.content-header {
  padding: 0.3em;
  border-left: rem(10px) solid $primary-dark;
  background-color: $off-white;
  border-radius: rem(5px);
  box-shadow: 0 rem(2px) rem(5px) rgba(0, 0, 0, 0.2);
  color: $dark-gray;
  margin: 0 0 rem(20px);

  &.error {
    border-color: $error;
  }
}

h1,
h2,
h3,
h4,
h5 {
  .content > & {
    @extend .content-header;
  }
}
