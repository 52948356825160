/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

@mixin fa-500px {
  .#{$fa-css-prefix}-500px:before {
    content: fa-content($fa-var-500px);
  }
}
@mixin fa-accessible-icon {
  .#{$fa-css-prefix}-accessible-icon:before {
    content: fa-content($fa-var-accessible-icon);
  }
}
@mixin fa-accusoft {
  .#{$fa-css-prefix}-accusoft:before {
    content: fa-content($fa-var-accusoft);
  }
}
@mixin fa-ad {
  .#{$fa-css-prefix}-ad:before {
    content: fa-content($fa-var-ad);
  }
}
@mixin fa-address-book {
  .#{$fa-css-prefix}-address-book:before {
    content: fa-content($fa-var-address-book);
  }
}
@mixin fa-address-card {
  .#{$fa-css-prefix}-address-card:before {
    content: fa-content($fa-var-address-card);
  }
}
@mixin fa-adjust {
  .#{$fa-css-prefix}-adjust:before {
    content: fa-content($fa-var-adjust);
  }
}
@mixin fa-adn {
  .#{$fa-css-prefix}-adn:before {
    content: fa-content($fa-var-adn);
  }
}
@mixin fa-adversal {
  .#{$fa-css-prefix}-adversal:before {
    content: fa-content($fa-var-adversal);
  }
}
@mixin fa-affiliatetheme {
  .#{$fa-css-prefix}-affiliatetheme:before {
    content: fa-content($fa-var-affiliatetheme);
  }
}
@mixin fa-air-freshener {
  .#{$fa-css-prefix}-air-freshener:before {
    content: fa-content($fa-var-air-freshener);
  }
}
@mixin fa-algolia {
  .#{$fa-css-prefix}-algolia:before {
    content: fa-content($fa-var-algolia);
  }
}
@mixin fa-align-center {
  .#{$fa-css-prefix}-align-center:before {
    content: fa-content($fa-var-align-center);
  }
}
@mixin fa-align-justify {
  .#{$fa-css-prefix}-align-justify:before {
    content: fa-content($fa-var-align-justify);
  }
}
@mixin fa-align-left {
  .#{$fa-css-prefix}-align-left:before {
    content: fa-content($fa-var-align-left);
  }
}
@mixin fa-align-right {
  .#{$fa-css-prefix}-align-right:before {
    content: fa-content($fa-var-align-right);
  }
}
@mixin fa-alipay {
  .#{$fa-css-prefix}-alipay:before {
    content: fa-content($fa-var-alipay);
  }
}
@mixin fa-allergies {
  .#{$fa-css-prefix}-allergies:before {
    content: fa-content($fa-var-allergies);
  }
}
@mixin fa-amazon {
  .#{$fa-css-prefix}-amazon:before {
    content: fa-content($fa-var-amazon);
  }
}
@mixin fa-amazon-pay {
  .#{$fa-css-prefix}-amazon-pay:before {
    content: fa-content($fa-var-amazon-pay);
  }
}
@mixin fa-ambulance {
  .#{$fa-css-prefix}-ambulance:before {
    content: fa-content($fa-var-ambulance);
  }
}
@mixin fa-american-sign-language-interpreting {
  .#{$fa-css-prefix}-american-sign-language-interpreting:before {
    content: fa-content($fa-var-american-sign-language-interpreting);
  }
}
@mixin fa-amilia {
  .#{$fa-css-prefix}-amilia:before {
    content: fa-content($fa-var-amilia);
  }
}
@mixin fa-anchor {
  .#{$fa-css-prefix}-anchor:before {
    content: fa-content($fa-var-anchor);
  }
}
@mixin fa-android {
  .#{$fa-css-prefix}-android:before {
    content: fa-content($fa-var-android);
  }
}
@mixin fa-angellist {
  .#{$fa-css-prefix}-angellist:before {
    content: fa-content($fa-var-angellist);
  }
}
@mixin fa-angle-double-down {
  .#{$fa-css-prefix}-angle-double-down:before {
    content: fa-content($fa-var-angle-double-down);
  }
}
@mixin fa-angle-double-left {
  .#{$fa-css-prefix}-angle-double-left:before {
    content: fa-content($fa-var-angle-double-left);
  }
}
@mixin fa-angle-double-right {
  .#{$fa-css-prefix}-angle-double-right:before {
    content: fa-content($fa-var-angle-double-right);
  }
}
@mixin fa-angle-double-up {
  .#{$fa-css-prefix}-angle-double-up:before {
    content: fa-content($fa-var-angle-double-up);
  }
}
@mixin fa-angle-down {
  .#{$fa-css-prefix}-angle-down:before {
    content: fa-content($fa-var-angle-down);
  }
}
@mixin fa-angle-left {
  .#{$fa-css-prefix}-angle-left:before {
    content: fa-content($fa-var-angle-left);
  }
}
@mixin fa-angle-right {
  .#{$fa-css-prefix}-angle-right:before {
    content: fa-content($fa-var-angle-right);
  }
}
@mixin fa-angle-up {
  .#{$fa-css-prefix}-angle-up:before {
    content: fa-content($fa-var-angle-up);
  }
}
@mixin fa-angry {
  .#{$fa-css-prefix}-angry:before {
    content: fa-content($fa-var-angry);
  }
}
@mixin fa-angrycreative {
  .#{$fa-css-prefix}-angrycreative:before {
    content: fa-content($fa-var-angrycreative);
  }
}
@mixin fa-angular {
  .#{$fa-css-prefix}-angular:before {
    content: fa-content($fa-var-angular);
  }
}
@mixin fa-ankh {
  .#{$fa-css-prefix}-ankh:before {
    content: fa-content($fa-var-ankh);
  }
}
@mixin fa-app-store {
  .#{$fa-css-prefix}-app-store:before {
    content: fa-content($fa-var-app-store);
  }
}
@mixin fa-app-store-ios {
  .#{$fa-css-prefix}-app-store-ios:before {
    content: fa-content($fa-var-app-store-ios);
  }
}
@mixin fa-apper {
  .#{$fa-css-prefix}-apper:before {
    content: fa-content($fa-var-apper);
  }
}
@mixin fa-apple {
  .#{$fa-css-prefix}-apple:before {
    content: fa-content($fa-var-apple);
  }
}
@mixin fa-apple-alt {
  .#{$fa-css-prefix}-apple-alt:before {
    content: fa-content($fa-var-apple-alt);
  }
}
@mixin fa-apple-pay {
  .#{$fa-css-prefix}-apple-pay:before {
    content: fa-content($fa-var-apple-pay);
  }
}
@mixin fa-archive {
  .#{$fa-css-prefix}-archive:before {
    content: fa-content($fa-var-archive);
  }
}
@mixin fa-archway {
  .#{$fa-css-prefix}-archway:before {
    content: fa-content($fa-var-archway);
  }
}
@mixin fa-arrow-alt-circle-down {
  .#{$fa-css-prefix}-arrow-alt-circle-down:before {
    content: fa-content($fa-var-arrow-alt-circle-down);
  }
}
@mixin fa-arrow-alt-circle-left {
  .#{$fa-css-prefix}-arrow-alt-circle-left:before {
    content: fa-content($fa-var-arrow-alt-circle-left);
  }
}
@mixin fa-arrow-alt-circle-right {
  .#{$fa-css-prefix}-arrow-alt-circle-right:before {
    content: fa-content($fa-var-arrow-alt-circle-right);
  }
}
@mixin fa-arrow-alt-circle-up {
  .#{$fa-css-prefix}-arrow-alt-circle-up:before {
    content: fa-content($fa-var-arrow-alt-circle-up);
  }
}
@mixin fa-arrow-circle-down {
  .#{$fa-css-prefix}-arrow-circle-down:before {
    content: fa-content($fa-var-arrow-circle-down);
  }
}
@mixin fa-arrow-circle-left {
  .#{$fa-css-prefix}-arrow-circle-left:before {
    content: fa-content($fa-var-arrow-circle-left);
  }
}
@mixin fa-arrow-circle-right {
  .#{$fa-css-prefix}-arrow-circle-right:before {
    content: fa-content($fa-var-arrow-circle-right);
  }
}
@mixin fa-arrow-circle-up {
  .#{$fa-css-prefix}-arrow-circle-up:before {
    content: fa-content($fa-var-arrow-circle-up);
  }
}
@mixin fa-arrow-down {
  .#{$fa-css-prefix}-arrow-down:before {
    content: fa-content($fa-var-arrow-down);
  }
}
@mixin fa-arrow-left {
  .#{$fa-css-prefix}-arrow-left:before {
    content: fa-content($fa-var-arrow-left);
  }
}
@mixin fa-arrow-right {
  .#{$fa-css-prefix}-arrow-right:before {
    content: fa-content($fa-var-arrow-right);
  }
}
@mixin fa-arrow-up {
  .#{$fa-css-prefix}-arrow-up:before {
    content: fa-content($fa-var-arrow-up);
  }
}
@mixin fa-arrows-alt {
  .#{$fa-css-prefix}-arrows-alt:before {
    content: fa-content($fa-var-arrows-alt);
  }
}
@mixin fa-arrows-alt-h {
  .#{$fa-css-prefix}-arrows-alt-h:before {
    content: fa-content($fa-var-arrows-alt-h);
  }
}
@mixin fa-arrows-alt-v {
  .#{$fa-css-prefix}-arrows-alt-v:before {
    content: fa-content($fa-var-arrows-alt-v);
  }
}
@mixin fa-assistive-listening-systems {
  .#{$fa-css-prefix}-assistive-listening-systems:before {
    content: fa-content($fa-var-assistive-listening-systems);
  }
}
@mixin fa-asterisk {
  .#{$fa-css-prefix}-asterisk:before {
    content: fa-content($fa-var-asterisk);
  }
}
@mixin fa-asymmetrik {
  .#{$fa-css-prefix}-asymmetrik:before {
    content: fa-content($fa-var-asymmetrik);
  }
}
@mixin fa-at {
  .#{$fa-css-prefix}-at:before {
    content: fa-content($fa-var-at);
  }
}
@mixin fa-atlas {
  .#{$fa-css-prefix}-atlas:before {
    content: fa-content($fa-var-atlas);
  }
}
@mixin fa-atom {
  .#{$fa-css-prefix}-atom:before {
    content: fa-content($fa-var-atom);
  }
}
@mixin fa-audible {
  .#{$fa-css-prefix}-audible:before {
    content: fa-content($fa-var-audible);
  }
}
@mixin fa-audio-description {
  .#{$fa-css-prefix}-audio-description:before {
    content: fa-content($fa-var-audio-description);
  }
}
@mixin fa-autoprefixer {
  .#{$fa-css-prefix}-autoprefixer:before {
    content: fa-content($fa-var-autoprefixer);
  }
}
@mixin fa-avianex {
  .#{$fa-css-prefix}-avianex:before {
    content: fa-content($fa-var-avianex);
  }
}
@mixin fa-aviato {
  .#{$fa-css-prefix}-aviato:before {
    content: fa-content($fa-var-aviato);
  }
}
@mixin fa-award {
  .#{$fa-css-prefix}-award:before {
    content: fa-content($fa-var-award);
  }
}
@mixin fa-aws {
  .#{$fa-css-prefix}-aws:before {
    content: fa-content($fa-var-aws);
  }
}
@mixin fa-backspace {
  .#{$fa-css-prefix}-backspace:before {
    content: fa-content($fa-var-backspace);
  }
}
@mixin fa-backward {
  .#{$fa-css-prefix}-backward:before {
    content: fa-content($fa-var-backward);
  }
}
@mixin fa-balance-scale {
  .#{$fa-css-prefix}-balance-scale:before {
    content: fa-content($fa-var-balance-scale);
  }
}
@mixin fa-ban {
  .#{$fa-css-prefix}-ban:before {
    content: fa-content($fa-var-ban);
  }
}
@mixin fa-band-aid {
  .#{$fa-css-prefix}-band-aid:before {
    content: fa-content($fa-var-band-aid);
  }
}
@mixin fa-bandcamp {
  .#{$fa-css-prefix}-bandcamp:before {
    content: fa-content($fa-var-bandcamp);
  }
}
@mixin fa-barcode {
  .#{$fa-css-prefix}-barcode:before {
    content: fa-content($fa-var-barcode);
  }
}
@mixin fa-bars {
  .#{$fa-css-prefix}-bars:before {
    content: fa-content($fa-var-bars);
  }
}
@mixin fa-baseball-ball {
  .#{$fa-css-prefix}-baseball-ball:before {
    content: fa-content($fa-var-baseball-ball);
  }
}
@mixin fa-basketball-ball {
  .#{$fa-css-prefix}-basketball-ball:before {
    content: fa-content($fa-var-basketball-ball);
  }
}
@mixin fa-bath {
  .#{$fa-css-prefix}-bath:before {
    content: fa-content($fa-var-bath);
  }
}
@mixin fa-battery-empty {
  .#{$fa-css-prefix}-battery-empty:before {
    content: fa-content($fa-var-battery-empty);
  }
}
@mixin fa-battery-full {
  .#{$fa-css-prefix}-battery-full:before {
    content: fa-content($fa-var-battery-full);
  }
}
@mixin fa-battery-half {
  .#{$fa-css-prefix}-battery-half:before {
    content: fa-content($fa-var-battery-half);
  }
}
@mixin fa-battery-quarter {
  .#{$fa-css-prefix}-battery-quarter:before {
    content: fa-content($fa-var-battery-quarter);
  }
}
@mixin fa-battery-three-quarters {
  .#{$fa-css-prefix}-battery-three-quarters:before {
    content: fa-content($fa-var-battery-three-quarters);
  }
}
@mixin fa-bed {
  .#{$fa-css-prefix}-bed:before {
    content: fa-content($fa-var-bed);
  }
}
@mixin fa-beer {
  .#{$fa-css-prefix}-beer:before {
    content: fa-content($fa-var-beer);
  }
}
@mixin fa-behance {
  .#{$fa-css-prefix}-behance:before {
    content: fa-content($fa-var-behance);
  }
}
@mixin fa-behance-square {
  .#{$fa-css-prefix}-behance-square:before {
    content: fa-content($fa-var-behance-square);
  }
}
@mixin fa-bell {
  .#{$fa-css-prefix}-bell:before {
    content: fa-content($fa-var-bell);
  }
}
@mixin fa-bell-slash {
  .#{$fa-css-prefix}-bell-slash:before {
    content: fa-content($fa-var-bell-slash);
  }
}
@mixin fa-bezier-curve {
  .#{$fa-css-prefix}-bezier-curve:before {
    content: fa-content($fa-var-bezier-curve);
  }
}
@mixin fa-bible {
  .#{$fa-css-prefix}-bible:before {
    content: fa-content($fa-var-bible);
  }
}
@mixin fa-bicycle {
  .#{$fa-css-prefix}-bicycle:before {
    content: fa-content($fa-var-bicycle);
  }
}
@mixin fa-bimobject {
  .#{$fa-css-prefix}-bimobject:before {
    content: fa-content($fa-var-bimobject);
  }
}
@mixin fa-binoculars {
  .#{$fa-css-prefix}-binoculars:before {
    content: fa-content($fa-var-binoculars);
  }
}
@mixin fa-birthday-cake {
  .#{$fa-css-prefix}-birthday-cake:before {
    content: fa-content($fa-var-birthday-cake);
  }
}
@mixin fa-bitbucket {
  .#{$fa-css-prefix}-bitbucket:before {
    content: fa-content($fa-var-bitbucket);
  }
}
@mixin fa-bitcoin {
  .#{$fa-css-prefix}-bitcoin:before {
    content: fa-content($fa-var-bitcoin);
  }
}
@mixin fa-bity {
  .#{$fa-css-prefix}-bity:before {
    content: fa-content($fa-var-bity);
  }
}
@mixin fa-black-tie {
  .#{$fa-css-prefix}-black-tie:before {
    content: fa-content($fa-var-black-tie);
  }
}
@mixin fa-blackberry {
  .#{$fa-css-prefix}-blackberry:before {
    content: fa-content($fa-var-blackberry);
  }
}
@mixin fa-blender {
  .#{$fa-css-prefix}-blender:before {
    content: fa-content($fa-var-blender);
  }
}
@mixin fa-blind {
  .#{$fa-css-prefix}-blind:before {
    content: fa-content($fa-var-blind);
  }
}
@mixin fa-blogger {
  .#{$fa-css-prefix}-blogger:before {
    content: fa-content($fa-var-blogger);
  }
}
@mixin fa-blogger-b {
  .#{$fa-css-prefix}-blogger-b:before {
    content: fa-content($fa-var-blogger-b);
  }
}
@mixin fa-bluetooth {
  .#{$fa-css-prefix}-bluetooth:before {
    content: fa-content($fa-var-bluetooth);
  }
}
@mixin fa-bluetooth-b {
  .#{$fa-css-prefix}-bluetooth-b:before {
    content: fa-content($fa-var-bluetooth-b);
  }
}
@mixin fa-bold {
  .#{$fa-css-prefix}-bold:before {
    content: fa-content($fa-var-bold);
  }
}
@mixin fa-bolt {
  .#{$fa-css-prefix}-bolt:before {
    content: fa-content($fa-var-bolt);
  }
}
@mixin fa-bomb {
  .#{$fa-css-prefix}-bomb:before {
    content: fa-content($fa-var-bomb);
  }
}
@mixin fa-bone {
  .#{$fa-css-prefix}-bone:before {
    content: fa-content($fa-var-bone);
  }
}
@mixin fa-bong {
  .#{$fa-css-prefix}-bong:before {
    content: fa-content($fa-var-bong);
  }
}
@mixin fa-book {
  .#{$fa-css-prefix}-book:before {
    content: fa-content($fa-var-book);
  }
}
@mixin fa-book-open {
  .#{$fa-css-prefix}-book-open:before {
    content: fa-content($fa-var-book-open);
  }
}
@mixin fa-book-reader {
  .#{$fa-css-prefix}-book-reader:before {
    content: fa-content($fa-var-book-reader);
  }
}
@mixin fa-bookmark {
  .#{$fa-css-prefix}-bookmark:before {
    content: fa-content($fa-var-bookmark);
  }
}
@mixin fa-bowling-ball {
  .#{$fa-css-prefix}-bowling-ball:before {
    content: fa-content($fa-var-bowling-ball);
  }
}
@mixin fa-box {
  .#{$fa-css-prefix}-box:before {
    content: fa-content($fa-var-box);
  }
}
@mixin fa-box-open {
  .#{$fa-css-prefix}-box-open:before {
    content: fa-content($fa-var-box-open);
  }
}
@mixin fa-boxes {
  .#{$fa-css-prefix}-boxes:before {
    content: fa-content($fa-var-boxes);
  }
}
@mixin fa-braille {
  .#{$fa-css-prefix}-braille:before {
    content: fa-content($fa-var-braille);
  }
}
@mixin fa-brain {
  .#{$fa-css-prefix}-brain:before {
    content: fa-content($fa-var-brain);
  }
}
@mixin fa-briefcase {
  .#{$fa-css-prefix}-briefcase:before {
    content: fa-content($fa-var-briefcase);
  }
}
@mixin fa-briefcase-medical {
  .#{$fa-css-prefix}-briefcase-medical:before {
    content: fa-content($fa-var-briefcase-medical);
  }
}
@mixin fa-broadcast-tower {
  .#{$fa-css-prefix}-broadcast-tower:before {
    content: fa-content($fa-var-broadcast-tower);
  }
}
@mixin fa-broom {
  .#{$fa-css-prefix}-broom:before {
    content: fa-content($fa-var-broom);
  }
}
@mixin fa-brush {
  .#{$fa-css-prefix}-brush:before {
    content: fa-content($fa-var-brush);
  }
}
@mixin fa-btc {
  .#{$fa-css-prefix}-btc:before {
    content: fa-content($fa-var-btc);
  }
}
@mixin fa-bug {
  .#{$fa-css-prefix}-bug:before {
    content: fa-content($fa-var-bug);
  }
}
@mixin fa-building {
  .#{$fa-css-prefix}-building:before {
    content: fa-content($fa-var-building);
  }
}
@mixin fa-bullhorn {
  .#{$fa-css-prefix}-bullhorn:before {
    content: fa-content($fa-var-bullhorn);
  }
}
@mixin fa-bullseye {
  .#{$fa-css-prefix}-bullseye:before {
    content: fa-content($fa-var-bullseye);
  }
}
@mixin fa-burn {
  .#{$fa-css-prefix}-burn:before {
    content: fa-content($fa-var-burn);
  }
}
@mixin fa-buromobelexperte {
  .#{$fa-css-prefix}-buromobelexperte:before {
    content: fa-content($fa-var-buromobelexperte);
  }
}
@mixin fa-bus {
  .#{$fa-css-prefix}-bus:before {
    content: fa-content($fa-var-bus);
  }
}
@mixin fa-bus-alt {
  .#{$fa-css-prefix}-bus-alt:before {
    content: fa-content($fa-var-bus-alt);
  }
}
@mixin fa-business-time {
  .#{$fa-css-prefix}-business-time:before {
    content: fa-content($fa-var-business-time);
  }
}
@mixin fa-buysellads {
  .#{$fa-css-prefix}-buysellads:before {
    content: fa-content($fa-var-buysellads);
  }
}
@mixin fa-calculator {
  .#{$fa-css-prefix}-calculator:before {
    content: fa-content($fa-var-calculator);
  }
}
@mixin fa-calendar {
  .#{$fa-css-prefix}-calendar:before {
    content: fa-content($fa-var-calendar);
  }
}
@mixin fa-calendar-alt {
  .#{$fa-css-prefix}-calendar-alt:before {
    content: fa-content($fa-var-calendar-alt);
  }
}
@mixin fa-calendar-check {
  .#{$fa-css-prefix}-calendar-check:before {
    content: fa-content($fa-var-calendar-check);
  }
}
@mixin fa-calendar-minus {
  .#{$fa-css-prefix}-calendar-minus:before {
    content: fa-content($fa-var-calendar-minus);
  }
}
@mixin fa-calendar-plus {
  .#{$fa-css-prefix}-calendar-plus:before {
    content: fa-content($fa-var-calendar-plus);
  }
}
@mixin fa-calendar-times {
  .#{$fa-css-prefix}-calendar-times:before {
    content: fa-content($fa-var-calendar-times);
  }
}
@mixin fa-camera {
  .#{$fa-css-prefix}-camera:before {
    content: fa-content($fa-var-camera);
  }
}
@mixin fa-camera-retro {
  .#{$fa-css-prefix}-camera-retro:before {
    content: fa-content($fa-var-camera-retro);
  }
}
@mixin fa-cannabis {
  .#{$fa-css-prefix}-cannabis:before {
    content: fa-content($fa-var-cannabis);
  }
}
@mixin fa-capsules {
  .#{$fa-css-prefix}-capsules:before {
    content: fa-content($fa-var-capsules);
  }
}
@mixin fa-car {
  .#{$fa-css-prefix}-car:before {
    content: fa-content($fa-var-car);
  }
}
@mixin fa-car-alt {
  .#{$fa-css-prefix}-car-alt:before {
    content: fa-content($fa-var-car-alt);
  }
}
@mixin fa-car-battery {
  .#{$fa-css-prefix}-car-battery:before {
    content: fa-content($fa-var-car-battery);
  }
}
@mixin fa-car-crash {
  .#{$fa-css-prefix}-car-crash:before {
    content: fa-content($fa-var-car-crash);
  }
}
@mixin fa-car-side {
  .#{$fa-css-prefix}-car-side:before {
    content: fa-content($fa-var-car-side);
  }
}
@mixin fa-caret-down {
  .#{$fa-css-prefix}-caret-down:before {
    content: fa-content($fa-var-caret-down);
  }
}
@mixin fa-caret-left {
  .#{$fa-css-prefix}-caret-left:before {
    content: fa-content($fa-var-caret-left);
  }
}
@mixin fa-caret-right {
  .#{$fa-css-prefix}-caret-right:before {
    content: fa-content($fa-var-caret-right);
  }
}
@mixin fa-caret-square-down {
  .#{$fa-css-prefix}-caret-square-down:before {
    content: fa-content($fa-var-caret-square-down);
  }
}
@mixin fa-caret-square-left {
  .#{$fa-css-prefix}-caret-square-left:before {
    content: fa-content($fa-var-caret-square-left);
  }
}
@mixin fa-caret-square-right {
  .#{$fa-css-prefix}-caret-square-right:before {
    content: fa-content($fa-var-caret-square-right);
  }
}
@mixin fa-caret-square-up {
  .#{$fa-css-prefix}-caret-square-up:before {
    content: fa-content($fa-var-caret-square-up);
  }
}
@mixin fa-caret-up {
  .#{$fa-css-prefix}-caret-up:before {
    content: fa-content($fa-var-caret-up);
  }
}
@mixin fa-cart-arrow-down {
  .#{$fa-css-prefix}-cart-arrow-down:before {
    content: fa-content($fa-var-cart-arrow-down);
  }
}
@mixin fa-cart-plus {
  .#{$fa-css-prefix}-cart-plus:before {
    content: fa-content($fa-var-cart-plus);
  }
}
@mixin fa-cc-amazon-pay {
  .#{$fa-css-prefix}-cc-amazon-pay:before {
    content: fa-content($fa-var-cc-amazon-pay);
  }
}
@mixin fa-cc-amex {
  .#{$fa-css-prefix}-cc-amex:before {
    content: fa-content($fa-var-cc-amex);
  }
}
@mixin fa-cc-apple-pay {
  .#{$fa-css-prefix}-cc-apple-pay:before {
    content: fa-content($fa-var-cc-apple-pay);
  }
}
@mixin fa-cc-diners-club {
  .#{$fa-css-prefix}-cc-diners-club:before {
    content: fa-content($fa-var-cc-diners-club);
  }
}
@mixin fa-cc-discover {
  .#{$fa-css-prefix}-cc-discover:before {
    content: fa-content($fa-var-cc-discover);
  }
}
@mixin fa-cc-jcb {
  .#{$fa-css-prefix}-cc-jcb:before {
    content: fa-content($fa-var-cc-jcb);
  }
}
@mixin fa-cc-mastercard {
  .#{$fa-css-prefix}-cc-mastercard:before {
    content: fa-content($fa-var-cc-mastercard);
  }
}
@mixin fa-cc-paypal {
  .#{$fa-css-prefix}-cc-paypal:before {
    content: fa-content($fa-var-cc-paypal);
  }
}
@mixin fa-cc-stripe {
  .#{$fa-css-prefix}-cc-stripe:before {
    content: fa-content($fa-var-cc-stripe);
  }
}
@mixin fa-cc-visa {
  .#{$fa-css-prefix}-cc-visa:before {
    content: fa-content($fa-var-cc-visa);
  }
}
@mixin fa-centercode {
  .#{$fa-css-prefix}-centercode:before {
    content: fa-content($fa-var-centercode);
  }
}
@mixin fa-certificate {
  .#{$fa-css-prefix}-certificate:before {
    content: fa-content($fa-var-certificate);
  }
}
@mixin fa-chalkboard {
  .#{$fa-css-prefix}-chalkboard:before {
    content: fa-content($fa-var-chalkboard);
  }
}
@mixin fa-chalkboard-teacher {
  .#{$fa-css-prefix}-chalkboard-teacher:before {
    content: fa-content($fa-var-chalkboard-teacher);
  }
}
@mixin fa-charging-station {
  .#{$fa-css-prefix}-charging-station:before {
    content: fa-content($fa-var-charging-station);
  }
}
@mixin fa-chart-area {
  .#{$fa-css-prefix}-chart-area:before {
    content: fa-content($fa-var-chart-area);
  }
}
@mixin fa-chart-bar {
  .#{$fa-css-prefix}-chart-bar:before {
    content: fa-content($fa-var-chart-bar);
  }
}
@mixin fa-chart-line {
  .#{$fa-css-prefix}-chart-line:before {
    content: fa-content($fa-var-chart-line);
  }
}
@mixin fa-chart-pie {
  .#{$fa-css-prefix}-chart-pie:before {
    content: fa-content($fa-var-chart-pie);
  }
}
@mixin fa-check {
  .#{$fa-css-prefix}-check:before {
    content: fa-content($fa-var-check);
  }
}
@mixin fa-check-circle {
  .#{$fa-css-prefix}-check-circle:before {
    content: fa-content($fa-var-check-circle);
  }
}
@mixin fa-check-double {
  .#{$fa-css-prefix}-check-double:before {
    content: fa-content($fa-var-check-double);
  }
}
@mixin fa-check-square {
  .#{$fa-css-prefix}-check-square:before {
    content: fa-content($fa-var-check-square);
  }
}
@mixin fa-chess {
  .#{$fa-css-prefix}-chess:before {
    content: fa-content($fa-var-chess);
  }
}
@mixin fa-chess-bishop {
  .#{$fa-css-prefix}-chess-bishop:before {
    content: fa-content($fa-var-chess-bishop);
  }
}
@mixin fa-chess-board {
  .#{$fa-css-prefix}-chess-board:before {
    content: fa-content($fa-var-chess-board);
  }
}
@mixin fa-chess-king {
  .#{$fa-css-prefix}-chess-king:before {
    content: fa-content($fa-var-chess-king);
  }
}
@mixin fa-chess-knight {
  .#{$fa-css-prefix}-chess-knight:before {
    content: fa-content($fa-var-chess-knight);
  }
}
@mixin fa-chess-pawn {
  .#{$fa-css-prefix}-chess-pawn:before {
    content: fa-content($fa-var-chess-pawn);
  }
}
@mixin fa-chess-queen {
  .#{$fa-css-prefix}-chess-queen:before {
    content: fa-content($fa-var-chess-queen);
  }
}
@mixin fa-chess-rook {
  .#{$fa-css-prefix}-chess-rook:before {
    content: fa-content($fa-var-chess-rook);
  }
}
@mixin fa-chevron-circle-down {
  .#{$fa-css-prefix}-chevron-circle-down:before {
    content: fa-content($fa-var-chevron-circle-down);
  }
}
@mixin fa-chevron-circle-left {
  .#{$fa-css-prefix}-chevron-circle-left:before {
    content: fa-content($fa-var-chevron-circle-left);
  }
}
@mixin fa-chevron-circle-right {
  .#{$fa-css-prefix}-chevron-circle-right:before {
    content: fa-content($fa-var-chevron-circle-right);
  }
}
@mixin fa-chevron-circle-up {
  .#{$fa-css-prefix}-chevron-circle-up:before {
    content: fa-content($fa-var-chevron-circle-up);
  }
}
@mixin fa-chevron-down {
  .#{$fa-css-prefix}-chevron-down:before {
    content: fa-content($fa-var-chevron-down);
  }
}
@mixin fa-chevron-left {
  .#{$fa-css-prefix}-chevron-left:before {
    content: fa-content($fa-var-chevron-left);
  }
}
@mixin fa-chevron-right {
  .#{$fa-css-prefix}-chevron-right:before {
    content: fa-content($fa-var-chevron-right);
  }
}
@mixin fa-chevron-up {
  .#{$fa-css-prefix}-chevron-up:before {
    content: fa-content($fa-var-chevron-up);
  }
}
@mixin fa-child {
  .#{$fa-css-prefix}-child:before {
    content: fa-content($fa-var-child);
  }
}
@mixin fa-chrome {
  .#{$fa-css-prefix}-chrome:before {
    content: fa-content($fa-var-chrome);
  }
}
@mixin fa-church {
  .#{$fa-css-prefix}-church:before {
    content: fa-content($fa-var-church);
  }
}
@mixin fa-circle {
  .#{$fa-css-prefix}-circle:before {
    content: fa-content($fa-var-circle);
  }
}
@mixin fa-circle-notch {
  .#{$fa-css-prefix}-circle-notch:before {
    content: fa-content($fa-var-circle-notch);
  }
}
@mixin fa-city {
  .#{$fa-css-prefix}-city:before {
    content: fa-content($fa-var-city);
  }
}
@mixin fa-clipboard {
  .#{$fa-css-prefix}-clipboard:before {
    content: fa-content($fa-var-clipboard);
  }
}
@mixin fa-clipboard-check {
  .#{$fa-css-prefix}-clipboard-check:before {
    content: fa-content($fa-var-clipboard-check);
  }
}
@mixin fa-clipboard-list {
  .#{$fa-css-prefix}-clipboard-list:before {
    content: fa-content($fa-var-clipboard-list);
  }
}
@mixin fa-clock {
  .#{$fa-css-prefix}-clock:before {
    content: fa-content($fa-var-clock);
  }
}
@mixin fa-clone {
  .#{$fa-css-prefix}-clone:before {
    content: fa-content($fa-var-clone);
  }
}
@mixin fa-closed-captioning {
  .#{$fa-css-prefix}-closed-captioning:before {
    content: fa-content($fa-var-closed-captioning);
  }
}
@mixin fa-cloud {
  .#{$fa-css-prefix}-cloud:before {
    content: fa-content($fa-var-cloud);
  }
}
@mixin fa-cloud-download-alt {
  .#{$fa-css-prefix}-cloud-download-alt:before {
    content: fa-content($fa-var-cloud-download-alt);
  }
}
@mixin fa-cloud-upload-alt {
  .#{$fa-css-prefix}-cloud-upload-alt:before {
    content: fa-content($fa-var-cloud-upload-alt);
  }
}
@mixin fa-cloudscale {
  .#{$fa-css-prefix}-cloudscale:before {
    content: fa-content($fa-var-cloudscale);
  }
}
@mixin fa-cloudsmith {
  .#{$fa-css-prefix}-cloudsmith:before {
    content: fa-content($fa-var-cloudsmith);
  }
}
@mixin fa-cloudversify {
  .#{$fa-css-prefix}-cloudversify:before {
    content: fa-content($fa-var-cloudversify);
  }
}
@mixin fa-cocktail {
  .#{$fa-css-prefix}-cocktail:before {
    content: fa-content($fa-var-cocktail);
  }
}
@mixin fa-code {
  .#{$fa-css-prefix}-code:before {
    content: fa-content($fa-var-code);
  }
}
@mixin fa-code-branch {
  .#{$fa-css-prefix}-code-branch:before {
    content: fa-content($fa-var-code-branch);
  }
}
@mixin fa-codepen {
  .#{$fa-css-prefix}-codepen:before {
    content: fa-content($fa-var-codepen);
  }
}
@mixin fa-codiepie {
  .#{$fa-css-prefix}-codiepie:before {
    content: fa-content($fa-var-codiepie);
  }
}
@mixin fa-coffee {
  .#{$fa-css-prefix}-coffee:before {
    content: fa-content($fa-var-coffee);
  }
}
@mixin fa-cog {
  .#{$fa-css-prefix}-cog:before {
    content: fa-content($fa-var-cog);
  }
}
@mixin fa-cogs {
  .#{$fa-css-prefix}-cogs:before {
    content: fa-content($fa-var-cogs);
  }
}
@mixin fa-coins {
  .#{$fa-css-prefix}-coins:before {
    content: fa-content($fa-var-coins);
  }
}
@mixin fa-columns {
  .#{$fa-css-prefix}-columns:before {
    content: fa-content($fa-var-columns);
  }
}
@mixin fa-comment {
  .#{$fa-css-prefix}-comment:before {
    content: fa-content($fa-var-comment);
  }
}
@mixin fa-comment-alt {
  .#{$fa-css-prefix}-comment-alt:before {
    content: fa-content($fa-var-comment-alt);
  }
}
@mixin fa-comment-dollar {
  .#{$fa-css-prefix}-comment-dollar:before {
    content: fa-content($fa-var-comment-dollar);
  }
}
@mixin fa-comment-dots {
  .#{$fa-css-prefix}-comment-dots:before {
    content: fa-content($fa-var-comment-dots);
  }
}
@mixin fa-comment-slash {
  .#{$fa-css-prefix}-comment-slash:before {
    content: fa-content($fa-var-comment-slash);
  }
}
@mixin fa-comments {
  .#{$fa-css-prefix}-comments:before {
    content: fa-content($fa-var-comments);
  }
}
@mixin fa-comments-dollar {
  .#{$fa-css-prefix}-comments-dollar:before {
    content: fa-content($fa-var-comments-dollar);
  }
}
@mixin fa-compact-disc {
  .#{$fa-css-prefix}-compact-disc:before {
    content: fa-content($fa-var-compact-disc);
  }
}
@mixin fa-compass {
  .#{$fa-css-prefix}-compass:before {
    content: fa-content($fa-var-compass);
  }
}
@mixin fa-compress {
  .#{$fa-css-prefix}-compress:before {
    content: fa-content($fa-var-compress);
  }
}
@mixin fa-concierge-bell {
  .#{$fa-css-prefix}-concierge-bell:before {
    content: fa-content($fa-var-concierge-bell);
  }
}
@mixin fa-connectdevelop {
  .#{$fa-css-prefix}-connectdevelop:before {
    content: fa-content($fa-var-connectdevelop);
  }
}
@mixin fa-contao {
  .#{$fa-css-prefix}-contao:before {
    content: fa-content($fa-var-contao);
  }
}
@mixin fa-cookie {
  .#{$fa-css-prefix}-cookie:before {
    content: fa-content($fa-var-cookie);
  }
}
@mixin fa-cookie-bite {
  .#{$fa-css-prefix}-cookie-bite:before {
    content: fa-content($fa-var-cookie-bite);
  }
}
@mixin fa-copy {
  .#{$fa-css-prefix}-copy:before {
    content: fa-content($fa-var-copy);
  }
}
@mixin fa-copyright {
  .#{$fa-css-prefix}-copyright:before {
    content: fa-content($fa-var-copyright);
  }
}
@mixin fa-couch {
  .#{$fa-css-prefix}-couch:before {
    content: fa-content($fa-var-couch);
  }
}
@mixin fa-cpanel {
  .#{$fa-css-prefix}-cpanel:before {
    content: fa-content($fa-var-cpanel);
  }
}
@mixin fa-creative-commons {
  .#{$fa-css-prefix}-creative-commons:before {
    content: fa-content($fa-var-creative-commons);
  }
}
@mixin fa-creative-commons-by {
  .#{$fa-css-prefix}-creative-commons-by:before {
    content: fa-content($fa-var-creative-commons-by);
  }
}
@mixin fa-creative-commons-nc {
  .#{$fa-css-prefix}-creative-commons-nc:before {
    content: fa-content($fa-var-creative-commons-nc);
  }
}
@mixin fa-creative-commons-nc-eu {
  .#{$fa-css-prefix}-creative-commons-nc-eu:before {
    content: fa-content($fa-var-creative-commons-nc-eu);
  }
}
@mixin fa-creative-commons-nc-jp {
  .#{$fa-css-prefix}-creative-commons-nc-jp:before {
    content: fa-content($fa-var-creative-commons-nc-jp);
  }
}
@mixin fa-creative-commons-nd {
  .#{$fa-css-prefix}-creative-commons-nd:before {
    content: fa-content($fa-var-creative-commons-nd);
  }
}
@mixin fa-creative-commons-pd {
  .#{$fa-css-prefix}-creative-commons-pd:before {
    content: fa-content($fa-var-creative-commons-pd);
  }
}
@mixin fa-creative-commons-pd-alt {
  .#{$fa-css-prefix}-creative-commons-pd-alt:before {
    content: fa-content($fa-var-creative-commons-pd-alt);
  }
}
@mixin fa-creative-commons-remix {
  .#{$fa-css-prefix}-creative-commons-remix:before {
    content: fa-content($fa-var-creative-commons-remix);
  }
}
@mixin fa-creative-commons-sa {
  .#{$fa-css-prefix}-creative-commons-sa:before {
    content: fa-content($fa-var-creative-commons-sa);
  }
}
@mixin fa-creative-commons-sampling {
  .#{$fa-css-prefix}-creative-commons-sampling:before {
    content: fa-content($fa-var-creative-commons-sampling);
  }
}
@mixin fa-creative-commons-sampling-plus {
  .#{$fa-css-prefix}-creative-commons-sampling-plus:before {
    content: fa-content($fa-var-creative-commons-sampling-plus);
  }
}
@mixin fa-creative-commons-share {
  .#{$fa-css-prefix}-creative-commons-share:before {
    content: fa-content($fa-var-creative-commons-share);
  }
}
@mixin fa-credit-card {
  .#{$fa-css-prefix}-credit-card:before {
    content: fa-content($fa-var-credit-card);
  }
}
@mixin fa-crop {
  .#{$fa-css-prefix}-crop:before {
    content: fa-content($fa-var-crop);
  }
}
@mixin fa-crop-alt {
  .#{$fa-css-prefix}-crop-alt:before {
    content: fa-content($fa-var-crop-alt);
  }
}
@mixin fa-cross {
  .#{$fa-css-prefix}-cross:before {
    content: fa-content($fa-var-cross);
  }
}
@mixin fa-crosshairs {
  .#{$fa-css-prefix}-crosshairs:before {
    content: fa-content($fa-var-crosshairs);
  }
}
@mixin fa-crow {
  .#{$fa-css-prefix}-crow:before {
    content: fa-content($fa-var-crow);
  }
}
@mixin fa-crown {
  .#{$fa-css-prefix}-crown:before {
    content: fa-content($fa-var-crown);
  }
}
@mixin fa-css3 {
  .#{$fa-css-prefix}-css3:before {
    content: fa-content($fa-var-css3);
  }
}
@mixin fa-css3-alt {
  .#{$fa-css-prefix}-css3-alt:before {
    content: fa-content($fa-var-css3-alt);
  }
}
@mixin fa-cube {
  .#{$fa-css-prefix}-cube:before {
    content: fa-content($fa-var-cube);
  }
}
@mixin fa-cubes {
  .#{$fa-css-prefix}-cubes:before {
    content: fa-content($fa-var-cubes);
  }
}
@mixin fa-cut {
  .#{$fa-css-prefix}-cut:before {
    content: fa-content($fa-var-cut);
  }
}
@mixin fa-cuttlefish {
  .#{$fa-css-prefix}-cuttlefish:before {
    content: fa-content($fa-var-cuttlefish);
  }
}
@mixin fa-d-and-d {
  .#{$fa-css-prefix}-d-and-d:before {
    content: fa-content($fa-var-d-and-d);
  }
}
@mixin fa-dashcube {
  .#{$fa-css-prefix}-dashcube:before {
    content: fa-content($fa-var-dashcube);
  }
}
@mixin fa-database {
  .#{$fa-css-prefix}-database:before {
    content: fa-content($fa-var-database);
  }
}
@mixin fa-deaf {
  .#{$fa-css-prefix}-deaf:before {
    content: fa-content($fa-var-deaf);
  }
}
@mixin fa-delicious {
  .#{$fa-css-prefix}-delicious:before {
    content: fa-content($fa-var-delicious);
  }
}
@mixin fa-deploydog {
  .#{$fa-css-prefix}-deploydog:before {
    content: fa-content($fa-var-deploydog);
  }
}
@mixin fa-deskpro {
  .#{$fa-css-prefix}-deskpro:before {
    content: fa-content($fa-var-deskpro);
  }
}
@mixin fa-desktop {
  .#{$fa-css-prefix}-desktop:before {
    content: fa-content($fa-var-desktop);
  }
}
@mixin fa-deviantart {
  .#{$fa-css-prefix}-deviantart:before {
    content: fa-content($fa-var-deviantart);
  }
}
@mixin fa-dharmachakra {
  .#{$fa-css-prefix}-dharmachakra:before {
    content: fa-content($fa-var-dharmachakra);
  }
}
@mixin fa-diagnoses {
  .#{$fa-css-prefix}-diagnoses:before {
    content: fa-content($fa-var-diagnoses);
  }
}
@mixin fa-dice {
  .#{$fa-css-prefix}-dice:before {
    content: fa-content($fa-var-dice);
  }
}
@mixin fa-dice-five {
  .#{$fa-css-prefix}-dice-five:before {
    content: fa-content($fa-var-dice-five);
  }
}
@mixin fa-dice-four {
  .#{$fa-css-prefix}-dice-four:before {
    content: fa-content($fa-var-dice-four);
  }
}
@mixin fa-dice-one {
  .#{$fa-css-prefix}-dice-one:before {
    content: fa-content($fa-var-dice-one);
  }
}
@mixin fa-dice-six {
  .#{$fa-css-prefix}-dice-six:before {
    content: fa-content($fa-var-dice-six);
  }
}
@mixin fa-dice-three {
  .#{$fa-css-prefix}-dice-three:before {
    content: fa-content($fa-var-dice-three);
  }
}
@mixin fa-dice-two {
  .#{$fa-css-prefix}-dice-two:before {
    content: fa-content($fa-var-dice-two);
  }
}
@mixin fa-digg {
  .#{$fa-css-prefix}-digg:before {
    content: fa-content($fa-var-digg);
  }
}
@mixin fa-digital-ocean {
  .#{$fa-css-prefix}-digital-ocean:before {
    content: fa-content($fa-var-digital-ocean);
  }
}
@mixin fa-digital-tachograph {
  .#{$fa-css-prefix}-digital-tachograph:before {
    content: fa-content($fa-var-digital-tachograph);
  }
}
@mixin fa-directions {
  .#{$fa-css-prefix}-directions:before {
    content: fa-content($fa-var-directions);
  }
}
@mixin fa-discord {
  .#{$fa-css-prefix}-discord:before {
    content: fa-content($fa-var-discord);
  }
}
@mixin fa-discourse {
  .#{$fa-css-prefix}-discourse:before {
    content: fa-content($fa-var-discourse);
  }
}
@mixin fa-divide {
  .#{$fa-css-prefix}-divide:before {
    content: fa-content($fa-var-divide);
  }
}
@mixin fa-dizzy {
  .#{$fa-css-prefix}-dizzy:before {
    content: fa-content($fa-var-dizzy);
  }
}
@mixin fa-dna {
  .#{$fa-css-prefix}-dna:before {
    content: fa-content($fa-var-dna);
  }
}
@mixin fa-dochub {
  .#{$fa-css-prefix}-dochub:before {
    content: fa-content($fa-var-dochub);
  }
}
@mixin fa-docker {
  .#{$fa-css-prefix}-docker:before {
    content: fa-content($fa-var-docker);
  }
}
@mixin fa-dollar-sign {
  .#{$fa-css-prefix}-dollar-sign:before {
    content: fa-content($fa-var-dollar-sign);
  }
}
@mixin fa-dolly {
  .#{$fa-css-prefix}-dolly:before {
    content: fa-content($fa-var-dolly);
  }
}
@mixin fa-dolly-flatbed {
  .#{$fa-css-prefix}-dolly-flatbed:before {
    content: fa-content($fa-var-dolly-flatbed);
  }
}
@mixin fa-donate {
  .#{$fa-css-prefix}-donate:before {
    content: fa-content($fa-var-donate);
  }
}
@mixin fa-door-closed {
  .#{$fa-css-prefix}-door-closed:before {
    content: fa-content($fa-var-door-closed);
  }
}
@mixin fa-door-open {
  .#{$fa-css-prefix}-door-open:before {
    content: fa-content($fa-var-door-open);
  }
}
@mixin fa-dot-circle {
  .#{$fa-css-prefix}-dot-circle:before {
    content: fa-content($fa-var-dot-circle);
  }
}
@mixin fa-dove {
  .#{$fa-css-prefix}-dove:before {
    content: fa-content($fa-var-dove);
  }
}
@mixin fa-download {
  .#{$fa-css-prefix}-download:before {
    content: fa-content($fa-var-download);
  }
}
@mixin fa-draft2digital {
  .#{$fa-css-prefix}-draft2digital:before {
    content: fa-content($fa-var-draft2digital);
  }
}
@mixin fa-drafting-compass {
  .#{$fa-css-prefix}-drafting-compass:before {
    content: fa-content($fa-var-drafting-compass);
  }
}
@mixin fa-draw-polygon {
  .#{$fa-css-prefix}-draw-polygon:before {
    content: fa-content($fa-var-draw-polygon);
  }
}
@mixin fa-dribbble {
  .#{$fa-css-prefix}-dribbble:before {
    content: fa-content($fa-var-dribbble);
  }
}
@mixin fa-dribbble-square {
  .#{$fa-css-prefix}-dribbble-square:before {
    content: fa-content($fa-var-dribbble-square);
  }
}
@mixin fa-dropbox {
  .#{$fa-css-prefix}-dropbox:before {
    content: fa-content($fa-var-dropbox);
  }
}
@mixin fa-drum {
  .#{$fa-css-prefix}-drum:before {
    content: fa-content($fa-var-drum);
  }
}
@mixin fa-drum-steelpan {
  .#{$fa-css-prefix}-drum-steelpan:before {
    content: fa-content($fa-var-drum-steelpan);
  }
}
@mixin fa-drupal {
  .#{$fa-css-prefix}-drupal:before {
    content: fa-content($fa-var-drupal);
  }
}
@mixin fa-dumbbell {
  .#{$fa-css-prefix}-dumbbell:before {
    content: fa-content($fa-var-dumbbell);
  }
}
@mixin fa-dyalog {
  .#{$fa-css-prefix}-dyalog:before {
    content: fa-content($fa-var-dyalog);
  }
}
@mixin fa-earlybirds {
  .#{$fa-css-prefix}-earlybirds:before {
    content: fa-content($fa-var-earlybirds);
  }
}
@mixin fa-ebay {
  .#{$fa-css-prefix}-ebay:before {
    content: fa-content($fa-var-ebay);
  }
}
@mixin fa-edge {
  .#{$fa-css-prefix}-edge:before {
    content: fa-content($fa-var-edge);
  }
}
@mixin fa-edit {
  .#{$fa-css-prefix}-edit:before {
    content: fa-content($fa-var-edit);
  }
}
@mixin fa-eject {
  .#{$fa-css-prefix}-eject:before {
    content: fa-content($fa-var-eject);
  }
}
@mixin fa-elementor {
  .#{$fa-css-prefix}-elementor:before {
    content: fa-content($fa-var-elementor);
  }
}
@mixin fa-ellipsis-h {
  .#{$fa-css-prefix}-ellipsis-h:before {
    content: fa-content($fa-var-ellipsis-h);
  }
}
@mixin fa-ellipsis-v {
  .#{$fa-css-prefix}-ellipsis-v:before {
    content: fa-content($fa-var-ellipsis-v);
  }
}
@mixin fa-ello {
  .#{$fa-css-prefix}-ello:before {
    content: fa-content($fa-var-ello);
  }
}
@mixin fa-ember {
  .#{$fa-css-prefix}-ember:before {
    content: fa-content($fa-var-ember);
  }
}
@mixin fa-empire {
  .#{$fa-css-prefix}-empire:before {
    content: fa-content($fa-var-empire);
  }
}
@mixin fa-envelope {
  .#{$fa-css-prefix}-envelope:before {
    content: fa-content($fa-var-envelope);
  }
}
@mixin fa-envelope-open {
  .#{$fa-css-prefix}-envelope-open:before {
    content: fa-content($fa-var-envelope-open);
  }
}
@mixin fa-envelope-open-text {
  .#{$fa-css-prefix}-envelope-open-text:before {
    content: fa-content($fa-var-envelope-open-text);
  }
}
@mixin fa-envelope-square {
  .#{$fa-css-prefix}-envelope-square:before {
    content: fa-content($fa-var-envelope-square);
  }
}
@mixin fa-envira {
  .#{$fa-css-prefix}-envira:before {
    content: fa-content($fa-var-envira);
  }
}
@mixin fa-equals {
  .#{$fa-css-prefix}-equals:before {
    content: fa-content($fa-var-equals);
  }
}
@mixin fa-eraser {
  .#{$fa-css-prefix}-eraser:before {
    content: fa-content($fa-var-eraser);
  }
}
@mixin fa-erlang {
  .#{$fa-css-prefix}-erlang:before {
    content: fa-content($fa-var-erlang);
  }
}
@mixin fa-ethereum {
  .#{$fa-css-prefix}-ethereum:before {
    content: fa-content($fa-var-ethereum);
  }
}
@mixin fa-etsy {
  .#{$fa-css-prefix}-etsy:before {
    content: fa-content($fa-var-etsy);
  }
}
@mixin fa-euro-sign {
  .#{$fa-css-prefix}-euro-sign:before {
    content: fa-content($fa-var-euro-sign);
  }
}
@mixin fa-exchange-alt {
  .#{$fa-css-prefix}-exchange-alt:before {
    content: fa-content($fa-var-exchange-alt);
  }
}
@mixin fa-exclamation {
  .#{$fa-css-prefix}-exclamation:before {
    content: fa-content($fa-var-exclamation);
  }
}
@mixin fa-exclamation-circle {
  .#{$fa-css-prefix}-exclamation-circle:before {
    content: fa-content($fa-var-exclamation-circle);
  }
}
@mixin fa-exclamation-triangle {
  .#{$fa-css-prefix}-exclamation-triangle:before {
    content: fa-content($fa-var-exclamation-triangle);
  }
}
@mixin fa-expand {
  .#{$fa-css-prefix}-expand:before {
    content: fa-content($fa-var-expand);
  }
}
@mixin fa-expand-arrows-alt {
  .#{$fa-css-prefix}-expand-arrows-alt:before {
    content: fa-content($fa-var-expand-arrows-alt);
  }
}
@mixin fa-expeditedssl {
  .#{$fa-css-prefix}-expeditedssl:before {
    content: fa-content($fa-var-expeditedssl);
  }
}
@mixin fa-external-link-alt {
  .#{$fa-css-prefix}-external-link-alt:before {
    content: fa-content($fa-var-external-link-alt);
  }
}
@mixin fa-external-link-square-alt {
  .#{$fa-css-prefix}-external-link-square-alt:before {
    content: fa-content($fa-var-external-link-square-alt);
  }
}
@mixin fa-eye {
  .#{$fa-css-prefix}-eye:before {
    content: fa-content($fa-var-eye);
  }
}
@mixin fa-eye-dropper {
  .#{$fa-css-prefix}-eye-dropper:before {
    content: fa-content($fa-var-eye-dropper);
  }
}
@mixin fa-eye-slash {
  .#{$fa-css-prefix}-eye-slash:before {
    content: fa-content($fa-var-eye-slash);
  }
}
@mixin fa-facebook {
  .#{$fa-css-prefix}-facebook:before {
    content: fa-content($fa-var-facebook);
  }
}
@mixin fa-facebook-f {
  .#{$fa-css-prefix}-facebook-f:before {
    content: fa-content($fa-var-facebook-f);
  }
}
@mixin fa-facebook-messenger {
  .#{$fa-css-prefix}-facebook-messenger:before {
    content: fa-content($fa-var-facebook-messenger);
  }
}
@mixin fa-facebook-square {
  .#{$fa-css-prefix}-facebook-square:before {
    content: fa-content($fa-var-facebook-square);
  }
}
@mixin fa-fast-backward {
  .#{$fa-css-prefix}-fast-backward:before {
    content: fa-content($fa-var-fast-backward);
  }
}
@mixin fa-fast-forward {
  .#{$fa-css-prefix}-fast-forward:before {
    content: fa-content($fa-var-fast-forward);
  }
}
@mixin fa-fax {
  .#{$fa-css-prefix}-fax:before {
    content: fa-content($fa-var-fax);
  }
}
@mixin fa-feather {
  .#{$fa-css-prefix}-feather:before {
    content: fa-content($fa-var-feather);
  }
}
@mixin fa-feather-alt {
  .#{$fa-css-prefix}-feather-alt:before {
    content: fa-content($fa-var-feather-alt);
  }
}
@mixin fa-female {
  .#{$fa-css-prefix}-female:before {
    content: fa-content($fa-var-female);
  }
}
@mixin fa-fighter-jet {
  .#{$fa-css-prefix}-fighter-jet:before {
    content: fa-content($fa-var-fighter-jet);
  }
}
@mixin fa-file {
  .#{$fa-css-prefix}-file:before {
    content: fa-content($fa-var-file);
  }
}
@mixin fa-file-alt {
  .#{$fa-css-prefix}-file-alt:before {
    content: fa-content($fa-var-file-alt);
  }
}
@mixin fa-file-archive {
  .#{$fa-css-prefix}-file-archive:before {
    content: fa-content($fa-var-file-archive);
  }
}
@mixin fa-file-audio {
  .#{$fa-css-prefix}-file-audio:before {
    content: fa-content($fa-var-file-audio);
  }
}
@mixin fa-file-code {
  .#{$fa-css-prefix}-file-code:before {
    content: fa-content($fa-var-file-code);
  }
}
@mixin fa-file-contract {
  .#{$fa-css-prefix}-file-contract:before {
    content: fa-content($fa-var-file-contract);
  }
}
@mixin fa-file-download {
  .#{$fa-css-prefix}-file-download:before {
    content: fa-content($fa-var-file-download);
  }
}
@mixin fa-file-excel {
  .#{$fa-css-prefix}-file-excel:before {
    content: fa-content($fa-var-file-excel);
  }
}
@mixin fa-file-export {
  .#{$fa-css-prefix}-file-export:before {
    content: fa-content($fa-var-file-export);
  }
}
@mixin fa-file-image {
  .#{$fa-css-prefix}-file-image:before {
    content: fa-content($fa-var-file-image);
  }
}
@mixin fa-file-import {
  .#{$fa-css-prefix}-file-import:before {
    content: fa-content($fa-var-file-import);
  }
}
@mixin fa-file-invoice {
  .#{$fa-css-prefix}-file-invoice:before {
    content: fa-content($fa-var-file-invoice);
  }
}
@mixin fa-file-invoice-dollar {
  .#{$fa-css-prefix}-file-invoice-dollar:before {
    content: fa-content($fa-var-file-invoice-dollar);
  }
}
@mixin fa-file-medical {
  .#{$fa-css-prefix}-file-medical:before {
    content: fa-content($fa-var-file-medical);
  }
}
@mixin fa-file-medical-alt {
  .#{$fa-css-prefix}-file-medical-alt:before {
    content: fa-content($fa-var-file-medical-alt);
  }
}
@mixin fa-file-pdf {
  .#{$fa-css-prefix}-file-pdf:before {
    content: fa-content($fa-var-file-pdf);
  }
}
@mixin fa-file-powerpoint {
  .#{$fa-css-prefix}-file-powerpoint:before {
    content: fa-content($fa-var-file-powerpoint);
  }
}
@mixin fa-file-prescription {
  .#{$fa-css-prefix}-file-prescription:before {
    content: fa-content($fa-var-file-prescription);
  }
}
@mixin fa-file-signature {
  .#{$fa-css-prefix}-file-signature:before {
    content: fa-content($fa-var-file-signature);
  }
}
@mixin fa-file-upload {
  .#{$fa-css-prefix}-file-upload:before {
    content: fa-content($fa-var-file-upload);
  }
}
@mixin fa-file-video {
  .#{$fa-css-prefix}-file-video:before {
    content: fa-content($fa-var-file-video);
  }
}
@mixin fa-file-word {
  .#{$fa-css-prefix}-file-word:before {
    content: fa-content($fa-var-file-word);
  }
}
@mixin fa-fill {
  .#{$fa-css-prefix}-fill:before {
    content: fa-content($fa-var-fill);
  }
}
@mixin fa-fill-drip {
  .#{$fa-css-prefix}-fill-drip:before {
    content: fa-content($fa-var-fill-drip);
  }
}
@mixin fa-film {
  .#{$fa-css-prefix}-film:before {
    content: fa-content($fa-var-film);
  }
}
@mixin fa-filter {
  .#{$fa-css-prefix}-filter:before {
    content: fa-content($fa-var-filter);
  }
}
@mixin fa-fingerprint {
  .#{$fa-css-prefix}-fingerprint:before {
    content: fa-content($fa-var-fingerprint);
  }
}
@mixin fa-fire {
  .#{$fa-css-prefix}-fire:before {
    content: fa-content($fa-var-fire);
  }
}
@mixin fa-fire-extinguisher {
  .#{$fa-css-prefix}-fire-extinguisher:before {
    content: fa-content($fa-var-fire-extinguisher);
  }
}
@mixin fa-firefox {
  .#{$fa-css-prefix}-firefox:before {
    content: fa-content($fa-var-firefox);
  }
}
@mixin fa-first-aid {
  .#{$fa-css-prefix}-first-aid:before {
    content: fa-content($fa-var-first-aid);
  }
}
@mixin fa-first-order {
  .#{$fa-css-prefix}-first-order:before {
    content: fa-content($fa-var-first-order);
  }
}
@mixin fa-first-order-alt {
  .#{$fa-css-prefix}-first-order-alt:before {
    content: fa-content($fa-var-first-order-alt);
  }
}
@mixin fa-firstdraft {
  .#{$fa-css-prefix}-firstdraft:before {
    content: fa-content($fa-var-firstdraft);
  }
}
@mixin fa-fish {
  .#{$fa-css-prefix}-fish:before {
    content: fa-content($fa-var-fish);
  }
}
@mixin fa-flag {
  .#{$fa-css-prefix}-flag:before {
    content: fa-content($fa-var-flag);
  }
}
@mixin fa-flag-checkered {
  .#{$fa-css-prefix}-flag-checkered:before {
    content: fa-content($fa-var-flag-checkered);
  }
}
@mixin fa-flask {
  .#{$fa-css-prefix}-flask:before {
    content: fa-content($fa-var-flask);
  }
}
@mixin fa-flickr {
  .#{$fa-css-prefix}-flickr:before {
    content: fa-content($fa-var-flickr);
  }
}
@mixin fa-flipboard {
  .#{$fa-css-prefix}-flipboard:before {
    content: fa-content($fa-var-flipboard);
  }
}
@mixin fa-flushed {
  .#{$fa-css-prefix}-flushed:before {
    content: fa-content($fa-var-flushed);
  }
}
@mixin fa-fly {
  .#{$fa-css-prefix}-fly:before {
    content: fa-content($fa-var-fly);
  }
}
@mixin fa-folder {
  .#{$fa-css-prefix}-folder:before {
    content: fa-content($fa-var-folder);
  }
}
@mixin fa-folder-minus {
  .#{$fa-css-prefix}-folder-minus:before {
    content: fa-content($fa-var-folder-minus);
  }
}
@mixin fa-folder-open {
  .#{$fa-css-prefix}-folder-open:before {
    content: fa-content($fa-var-folder-open);
  }
}
@mixin fa-folder-plus {
  .#{$fa-css-prefix}-folder-plus:before {
    content: fa-content($fa-var-folder-plus);
  }
}
@mixin fa-font {
  .#{$fa-css-prefix}-font:before {
    content: fa-content($fa-var-font);
  }
}
@mixin fa-font-awesome {
  .#{$fa-css-prefix}-font-awesome:before {
    content: fa-content($fa-var-font-awesome);
  }
}
@mixin fa-font-awesome-alt {
  .#{$fa-css-prefix}-font-awesome-alt:before {
    content: fa-content($fa-var-font-awesome-alt);
  }
}
@mixin fa-font-awesome-flag {
  .#{$fa-css-prefix}-font-awesome-flag:before {
    content: fa-content($fa-var-font-awesome-flag);
  }
}
@mixin fa-font-awesome-logo-full {
  .#{$fa-css-prefix}-font-awesome-logo-full:before {
    content: fa-content($fa-var-font-awesome-logo-full);
  }
}
@mixin fa-fonticons {
  .#{$fa-css-prefix}-fonticons:before {
    content: fa-content($fa-var-fonticons);
  }
}
@mixin fa-fonticons-fi {
  .#{$fa-css-prefix}-fonticons-fi:before {
    content: fa-content($fa-var-fonticons-fi);
  }
}
@mixin fa-football-ball {
  .#{$fa-css-prefix}-football-ball:before {
    content: fa-content($fa-var-football-ball);
  }
}
@mixin fa-fort-awesome {
  .#{$fa-css-prefix}-fort-awesome:before {
    content: fa-content($fa-var-fort-awesome);
  }
}
@mixin fa-fort-awesome-alt {
  .#{$fa-css-prefix}-fort-awesome-alt:before {
    content: fa-content($fa-var-fort-awesome-alt);
  }
}
@mixin fa-forumbee {
  .#{$fa-css-prefix}-forumbee:before {
    content: fa-content($fa-var-forumbee);
  }
}
@mixin fa-forward {
  .#{$fa-css-prefix}-forward:before {
    content: fa-content($fa-var-forward);
  }
}
@mixin fa-foursquare {
  .#{$fa-css-prefix}-foursquare:before {
    content: fa-content($fa-var-foursquare);
  }
}
@mixin fa-free-code-camp {
  .#{$fa-css-prefix}-free-code-camp:before {
    content: fa-content($fa-var-free-code-camp);
  }
}
@mixin fa-freebsd {
  .#{$fa-css-prefix}-freebsd:before {
    content: fa-content($fa-var-freebsd);
  }
}
@mixin fa-frog {
  .#{$fa-css-prefix}-frog:before {
    content: fa-content($fa-var-frog);
  }
}
@mixin fa-frown {
  .#{$fa-css-prefix}-frown:before {
    content: fa-content($fa-var-frown);
  }
}
@mixin fa-frown-open {
  .#{$fa-css-prefix}-frown-open:before {
    content: fa-content($fa-var-frown-open);
  }
}
@mixin fa-fulcrum {
  .#{$fa-css-prefix}-fulcrum:before {
    content: fa-content($fa-var-fulcrum);
  }
}
@mixin fa-funnel-dollar {
  .#{$fa-css-prefix}-funnel-dollar:before {
    content: fa-content($fa-var-funnel-dollar);
  }
}
@mixin fa-futbol {
  .#{$fa-css-prefix}-futbol:before {
    content: fa-content($fa-var-futbol);
  }
}
@mixin fa-galactic-republic {
  .#{$fa-css-prefix}-galactic-republic:before {
    content: fa-content($fa-var-galactic-republic);
  }
}
@mixin fa-galactic-senate {
  .#{$fa-css-prefix}-galactic-senate:before {
    content: fa-content($fa-var-galactic-senate);
  }
}
@mixin fa-gamepad {
  .#{$fa-css-prefix}-gamepad:before {
    content: fa-content($fa-var-gamepad);
  }
}
@mixin fa-gas-pump {
  .#{$fa-css-prefix}-gas-pump:before {
    content: fa-content($fa-var-gas-pump);
  }
}
@mixin fa-gavel {
  .#{$fa-css-prefix}-gavel:before {
    content: fa-content($fa-var-gavel);
  }
}
@mixin fa-gem {
  .#{$fa-css-prefix}-gem:before {
    content: fa-content($fa-var-gem);
  }
}
@mixin fa-genderless {
  .#{$fa-css-prefix}-genderless:before {
    content: fa-content($fa-var-genderless);
  }
}
@mixin fa-get-pocket {
  .#{$fa-css-prefix}-get-pocket:before {
    content: fa-content($fa-var-get-pocket);
  }
}
@mixin fa-gg {
  .#{$fa-css-prefix}-gg:before {
    content: fa-content($fa-var-gg);
  }
}
@mixin fa-gg-circle {
  .#{$fa-css-prefix}-gg-circle:before {
    content: fa-content($fa-var-gg-circle);
  }
}
@mixin fa-gift {
  .#{$fa-css-prefix}-gift:before {
    content: fa-content($fa-var-gift);
  }
}
@mixin fa-git {
  .#{$fa-css-prefix}-git:before {
    content: fa-content($fa-var-git);
  }
}
@mixin fa-git-square {
  .#{$fa-css-prefix}-git-square:before {
    content: fa-content($fa-var-git-square);
  }
}
@mixin fa-github {
  .#{$fa-css-prefix}-github:before {
    content: fa-content($fa-var-github);
  }
}
@mixin fa-github-alt {
  .#{$fa-css-prefix}-github-alt:before {
    content: fa-content($fa-var-github-alt);
  }
}
@mixin fa-github-square {
  .#{$fa-css-prefix}-github-square:before {
    content: fa-content($fa-var-github-square);
  }
}
@mixin fa-gitkraken {
  .#{$fa-css-prefix}-gitkraken:before {
    content: fa-content($fa-var-gitkraken);
  }
}
@mixin fa-gitlab {
  .#{$fa-css-prefix}-gitlab:before {
    content: fa-content($fa-var-gitlab);
  }
}
@mixin fa-gitter {
  .#{$fa-css-prefix}-gitter:before {
    content: fa-content($fa-var-gitter);
  }
}
@mixin fa-glass-martini {
  .#{$fa-css-prefix}-glass-martini:before {
    content: fa-content($fa-var-glass-martini);
  }
}
@mixin fa-glass-martini-alt {
  .#{$fa-css-prefix}-glass-martini-alt:before {
    content: fa-content($fa-var-glass-martini-alt);
  }
}
@mixin fa-glasses {
  .#{$fa-css-prefix}-glasses:before {
    content: fa-content($fa-var-glasses);
  }
}
@mixin fa-glide {
  .#{$fa-css-prefix}-glide:before {
    content: fa-content($fa-var-glide);
  }
}
@mixin fa-glide-g {
  .#{$fa-css-prefix}-glide-g:before {
    content: fa-content($fa-var-glide-g);
  }
}
@mixin fa-globe {
  .#{$fa-css-prefix}-globe:before {
    content: fa-content($fa-var-globe);
  }
}
@mixin fa-globe-africa {
  .#{$fa-css-prefix}-globe-africa:before {
    content: fa-content($fa-var-globe-africa);
  }
}
@mixin fa-globe-americas {
  .#{$fa-css-prefix}-globe-americas:before {
    content: fa-content($fa-var-globe-americas);
  }
}
@mixin fa-globe-asia {
  .#{$fa-css-prefix}-globe-asia:before {
    content: fa-content($fa-var-globe-asia);
  }
}
@mixin fa-gofore {
  .#{$fa-css-prefix}-gofore:before {
    content: fa-content($fa-var-gofore);
  }
}
@mixin fa-golf-ball {
  .#{$fa-css-prefix}-golf-ball:before {
    content: fa-content($fa-var-golf-ball);
  }
}
@mixin fa-goodreads {
  .#{$fa-css-prefix}-goodreads:before {
    content: fa-content($fa-var-goodreads);
  }
}
@mixin fa-goodreads-g {
  .#{$fa-css-prefix}-goodreads-g:before {
    content: fa-content($fa-var-goodreads-g);
  }
}
@mixin fa-google {
  .#{$fa-css-prefix}-google:before {
    content: fa-content($fa-var-google);
  }
}
@mixin fa-google-drive {
  .#{$fa-css-prefix}-google-drive:before {
    content: fa-content($fa-var-google-drive);
  }
}
@mixin fa-google-play {
  .#{$fa-css-prefix}-google-play:before {
    content: fa-content($fa-var-google-play);
  }
}
@mixin fa-google-plus {
  .#{$fa-css-prefix}-google-plus:before {
    content: fa-content($fa-var-google-plus);
  }
}
@mixin fa-google-plus-g {
  .#{$fa-css-prefix}-google-plus-g:before {
    content: fa-content($fa-var-google-plus-g);
  }
}
@mixin fa-google-plus-square {
  .#{$fa-css-prefix}-google-plus-square:before {
    content: fa-content($fa-var-google-plus-square);
  }
}
@mixin fa-google-wallet {
  .#{$fa-css-prefix}-google-wallet:before {
    content: fa-content($fa-var-google-wallet);
  }
}
@mixin fa-gopuram {
  .#{$fa-css-prefix}-gopuram:before {
    content: fa-content($fa-var-gopuram);
  }
}
@mixin fa-graduation-cap {
  .#{$fa-css-prefix}-graduation-cap:before {
    content: fa-content($fa-var-graduation-cap);
  }
}
@mixin fa-gratipay {
  .#{$fa-css-prefix}-gratipay:before {
    content: fa-content($fa-var-gratipay);
  }
}
@mixin fa-grav {
  .#{$fa-css-prefix}-grav:before {
    content: fa-content($fa-var-grav);
  }
}
@mixin fa-greater-than {
  .#{$fa-css-prefix}-greater-than:before {
    content: fa-content($fa-var-greater-than);
  }
}
@mixin fa-greater-than-equal {
  .#{$fa-css-prefix}-greater-than-equal:before {
    content: fa-content($fa-var-greater-than-equal);
  }
}
@mixin fa-grimace {
  .#{$fa-css-prefix}-grimace:before {
    content: fa-content($fa-var-grimace);
  }
}
@mixin fa-grin {
  .#{$fa-css-prefix}-grin:before {
    content: fa-content($fa-var-grin);
  }
}
@mixin fa-grin-alt {
  .#{$fa-css-prefix}-grin-alt:before {
    content: fa-content($fa-var-grin-alt);
  }
}
@mixin fa-grin-beam {
  .#{$fa-css-prefix}-grin-beam:before {
    content: fa-content($fa-var-grin-beam);
  }
}
@mixin fa-grin-beam-sweat {
  .#{$fa-css-prefix}-grin-beam-sweat:before {
    content: fa-content($fa-var-grin-beam-sweat);
  }
}
@mixin fa-grin-hearts {
  .#{$fa-css-prefix}-grin-hearts:before {
    content: fa-content($fa-var-grin-hearts);
  }
}
@mixin fa-grin-squint {
  .#{$fa-css-prefix}-grin-squint:before {
    content: fa-content($fa-var-grin-squint);
  }
}
@mixin fa-grin-squint-tears {
  .#{$fa-css-prefix}-grin-squint-tears:before {
    content: fa-content($fa-var-grin-squint-tears);
  }
}
@mixin fa-grin-stars {
  .#{$fa-css-prefix}-grin-stars:before {
    content: fa-content($fa-var-grin-stars);
  }
}
@mixin fa-grin-tears {
  .#{$fa-css-prefix}-grin-tears:before {
    content: fa-content($fa-var-grin-tears);
  }
}
@mixin fa-grin-tongue {
  .#{$fa-css-prefix}-grin-tongue:before {
    content: fa-content($fa-var-grin-tongue);
  }
}
@mixin fa-grin-tongue-squint {
  .#{$fa-css-prefix}-grin-tongue-squint:before {
    content: fa-content($fa-var-grin-tongue-squint);
  }
}
@mixin fa-grin-tongue-wink {
  .#{$fa-css-prefix}-grin-tongue-wink:before {
    content: fa-content($fa-var-grin-tongue-wink);
  }
}
@mixin fa-grin-wink {
  .#{$fa-css-prefix}-grin-wink:before {
    content: fa-content($fa-var-grin-wink);
  }
}
@mixin fa-grip-horizontal {
  .#{$fa-css-prefix}-grip-horizontal:before {
    content: fa-content($fa-var-grip-horizontal);
  }
}
@mixin fa-grip-vertical {
  .#{$fa-css-prefix}-grip-vertical:before {
    content: fa-content($fa-var-grip-vertical);
  }
}
@mixin fa-gripfire {
  .#{$fa-css-prefix}-gripfire:before {
    content: fa-content($fa-var-gripfire);
  }
}
@mixin fa-grunt {
  .#{$fa-css-prefix}-grunt:before {
    content: fa-content($fa-var-grunt);
  }
}
@mixin fa-gulp {
  .#{$fa-css-prefix}-gulp:before {
    content: fa-content($fa-var-gulp);
  }
}
@mixin fa-h-square {
  .#{$fa-css-prefix}-h-square:before {
    content: fa-content($fa-var-h-square);
  }
}
@mixin fa-hacker-news {
  .#{$fa-css-prefix}-hacker-news:before {
    content: fa-content($fa-var-hacker-news);
  }
}
@mixin fa-hacker-news-square {
  .#{$fa-css-prefix}-hacker-news-square:before {
    content: fa-content($fa-var-hacker-news-square);
  }
}
@mixin fa-hackerrank {
  .#{$fa-css-prefix}-hackerrank:before {
    content: fa-content($fa-var-hackerrank);
  }
}
@mixin fa-hamsa {
  .#{$fa-css-prefix}-hamsa:before {
    content: fa-content($fa-var-hamsa);
  }
}
@mixin fa-hand-holding {
  .#{$fa-css-prefix}-hand-holding:before {
    content: fa-content($fa-var-hand-holding);
  }
}
@mixin fa-hand-holding-heart {
  .#{$fa-css-prefix}-hand-holding-heart:before {
    content: fa-content($fa-var-hand-holding-heart);
  }
}
@mixin fa-hand-holding-usd {
  .#{$fa-css-prefix}-hand-holding-usd:before {
    content: fa-content($fa-var-hand-holding-usd);
  }
}
@mixin fa-hand-lizard {
  .#{$fa-css-prefix}-hand-lizard:before {
    content: fa-content($fa-var-hand-lizard);
  }
}
@mixin fa-hand-paper {
  .#{$fa-css-prefix}-hand-paper:before {
    content: fa-content($fa-var-hand-paper);
  }
}
@mixin fa-hand-peace {
  .#{$fa-css-prefix}-hand-peace:before {
    content: fa-content($fa-var-hand-peace);
  }
}
@mixin fa-hand-point-down {
  .#{$fa-css-prefix}-hand-point-down:before {
    content: fa-content($fa-var-hand-point-down);
  }
}
@mixin fa-hand-point-left {
  .#{$fa-css-prefix}-hand-point-left:before {
    content: fa-content($fa-var-hand-point-left);
  }
}
@mixin fa-hand-point-right {
  .#{$fa-css-prefix}-hand-point-right:before {
    content: fa-content($fa-var-hand-point-right);
  }
}
@mixin fa-hand-point-up {
  .#{$fa-css-prefix}-hand-point-up:before {
    content: fa-content($fa-var-hand-point-up);
  }
}
@mixin fa-hand-pointer {
  .#{$fa-css-prefix}-hand-pointer:before {
    content: fa-content($fa-var-hand-pointer);
  }
}
@mixin fa-hand-rock {
  .#{$fa-css-prefix}-hand-rock:before {
    content: fa-content($fa-var-hand-rock);
  }
}
@mixin fa-hand-scissors {
  .#{$fa-css-prefix}-hand-scissors:before {
    content: fa-content($fa-var-hand-scissors);
  }
}
@mixin fa-hand-spock {
  .#{$fa-css-prefix}-hand-spock:before {
    content: fa-content($fa-var-hand-spock);
  }
}
@mixin fa-hands {
  .#{$fa-css-prefix}-hands:before {
    content: fa-content($fa-var-hands);
  }
}
@mixin fa-hands-helping {
  .#{$fa-css-prefix}-hands-helping:before {
    content: fa-content($fa-var-hands-helping);
  }
}
@mixin fa-handshake {
  .#{$fa-css-prefix}-handshake:before {
    content: fa-content($fa-var-handshake);
  }
}
@mixin fa-hashtag {
  .#{$fa-css-prefix}-hashtag:before {
    content: fa-content($fa-var-hashtag);
  }
}
@mixin fa-haykal {
  .#{$fa-css-prefix}-haykal:before {
    content: fa-content($fa-var-haykal);
  }
}
@mixin fa-hdd {
  .#{$fa-css-prefix}-hdd:before {
    content: fa-content($fa-var-hdd);
  }
}
@mixin fa-heading {
  .#{$fa-css-prefix}-heading:before {
    content: fa-content($fa-var-heading);
  }
}
@mixin fa-headphones {
  .#{$fa-css-prefix}-headphones:before {
    content: fa-content($fa-var-headphones);
  }
}
@mixin fa-headphones-alt {
  .#{$fa-css-prefix}-headphones-alt:before {
    content: fa-content($fa-var-headphones-alt);
  }
}
@mixin fa-headset {
  .#{$fa-css-prefix}-headset:before {
    content: fa-content($fa-var-headset);
  }
}
@mixin fa-heart {
  .#{$fa-css-prefix}-heart:before {
    content: fa-content($fa-var-heart);
  }
}
@mixin fa-heartbeat {
  .#{$fa-css-prefix}-heartbeat:before {
    content: fa-content($fa-var-heartbeat);
  }
}
@mixin fa-helicopter {
  .#{$fa-css-prefix}-helicopter:before {
    content: fa-content($fa-var-helicopter);
  }
}
@mixin fa-highlighter {
  .#{$fa-css-prefix}-highlighter:before {
    content: fa-content($fa-var-highlighter);
  }
}
@mixin fa-hips {
  .#{$fa-css-prefix}-hips:before {
    content: fa-content($fa-var-hips);
  }
}
@mixin fa-hire-a-helper {
  .#{$fa-css-prefix}-hire-a-helper:before {
    content: fa-content($fa-var-hire-a-helper);
  }
}
@mixin fa-history {
  .#{$fa-css-prefix}-history:before {
    content: fa-content($fa-var-history);
  }
}
@mixin fa-hockey-puck {
  .#{$fa-css-prefix}-hockey-puck:before {
    content: fa-content($fa-var-hockey-puck);
  }
}
@mixin fa-home {
  .#{$fa-css-prefix}-home:before {
    content: fa-content($fa-var-home);
  }
}
@mixin fa-hooli {
  .#{$fa-css-prefix}-hooli:before {
    content: fa-content($fa-var-hooli);
  }
}
@mixin fa-hornbill {
  .#{$fa-css-prefix}-hornbill:before {
    content: fa-content($fa-var-hornbill);
  }
}
@mixin fa-hospital {
  .#{$fa-css-prefix}-hospital:before {
    content: fa-content($fa-var-hospital);
  }
}
@mixin fa-hospital-alt {
  .#{$fa-css-prefix}-hospital-alt:before {
    content: fa-content($fa-var-hospital-alt);
  }
}
@mixin fa-hospital-symbol {
  .#{$fa-css-prefix}-hospital-symbol:before {
    content: fa-content($fa-var-hospital-symbol);
  }
}
@mixin fa-hot-tub {
  .#{$fa-css-prefix}-hot-tub:before {
    content: fa-content($fa-var-hot-tub);
  }
}
@mixin fa-hotel {
  .#{$fa-css-prefix}-hotel:before {
    content: fa-content($fa-var-hotel);
  }
}
@mixin fa-hotjar {
  .#{$fa-css-prefix}-hotjar:before {
    content: fa-content($fa-var-hotjar);
  }
}
@mixin fa-hourglass {
  .#{$fa-css-prefix}-hourglass:before {
    content: fa-content($fa-var-hourglass);
  }
}
@mixin fa-hourglass-end {
  .#{$fa-css-prefix}-hourglass-end:before {
    content: fa-content($fa-var-hourglass-end);
  }
}
@mixin fa-hourglass-half {
  .#{$fa-css-prefix}-hourglass-half:before {
    content: fa-content($fa-var-hourglass-half);
  }
}
@mixin fa-hourglass-start {
  .#{$fa-css-prefix}-hourglass-start:before {
    content: fa-content($fa-var-hourglass-start);
  }
}
@mixin fa-houzz {
  .#{$fa-css-prefix}-houzz:before {
    content: fa-content($fa-var-houzz);
  }
}
@mixin fa-html5 {
  .#{$fa-css-prefix}-html5:before {
    content: fa-content($fa-var-html5);
  }
}
@mixin fa-hubspot {
  .#{$fa-css-prefix}-hubspot:before {
    content: fa-content($fa-var-hubspot);
  }
}
@mixin fa-i-cursor {
  .#{$fa-css-prefix}-i-cursor:before {
    content: fa-content($fa-var-i-cursor);
  }
}
@mixin fa-id-badge {
  .#{$fa-css-prefix}-id-badge:before {
    content: fa-content($fa-var-id-badge);
  }
}
@mixin fa-id-card {
  .#{$fa-css-prefix}-id-card:before {
    content: fa-content($fa-var-id-card);
  }
}
@mixin fa-id-card-alt {
  .#{$fa-css-prefix}-id-card-alt:before {
    content: fa-content($fa-var-id-card-alt);
  }
}
@mixin fa-image {
  .#{$fa-css-prefix}-image:before {
    content: fa-content($fa-var-image);
  }
}
@mixin fa-images {
  .#{$fa-css-prefix}-images:before {
    content: fa-content($fa-var-images);
  }
}
@mixin fa-imdb {
  .#{$fa-css-prefix}-imdb:before {
    content: fa-content($fa-var-imdb);
  }
}
@mixin fa-inbox {
  .#{$fa-css-prefix}-inbox:before {
    content: fa-content($fa-var-inbox);
  }
}
@mixin fa-indent {
  .#{$fa-css-prefix}-indent:before {
    content: fa-content($fa-var-indent);
  }
}
@mixin fa-industry {
  .#{$fa-css-prefix}-industry:before {
    content: fa-content($fa-var-industry);
  }
}
@mixin fa-infinity {
  .#{$fa-css-prefix}-infinity:before {
    content: fa-content($fa-var-infinity);
  }
}
@mixin fa-info {
  .#{$fa-css-prefix}-info:before {
    content: fa-content($fa-var-info);
  }
}
@mixin fa-info-circle {
  .#{$fa-css-prefix}-info-circle:before {
    content: fa-content($fa-var-info-circle);
  }
}
@mixin fa-instagram {
  .#{$fa-css-prefix}-instagram:before {
    content: fa-content($fa-var-instagram);
  }
}
@mixin fa-internet-explorer {
  .#{$fa-css-prefix}-internet-explorer:before {
    content: fa-content($fa-var-internet-explorer);
  }
}
@mixin fa-ioxhost {
  .#{$fa-css-prefix}-ioxhost:before {
    content: fa-content($fa-var-ioxhost);
  }
}
@mixin fa-italic {
  .#{$fa-css-prefix}-italic:before {
    content: fa-content($fa-var-italic);
  }
}
@mixin fa-itunes {
  .#{$fa-css-prefix}-itunes:before {
    content: fa-content($fa-var-itunes);
  }
}
@mixin fa-itunes-note {
  .#{$fa-css-prefix}-itunes-note:before {
    content: fa-content($fa-var-itunes-note);
  }
}
@mixin fa-java {
  .#{$fa-css-prefix}-java:before {
    content: fa-content($fa-var-java);
  }
}
@mixin fa-jedi {
  .#{$fa-css-prefix}-jedi:before {
    content: fa-content($fa-var-jedi);
  }
}
@mixin fa-jedi-order {
  .#{$fa-css-prefix}-jedi-order:before {
    content: fa-content($fa-var-jedi-order);
  }
}
@mixin fa-jenkins {
  .#{$fa-css-prefix}-jenkins:before {
    content: fa-content($fa-var-jenkins);
  }
}
@mixin fa-joget {
  .#{$fa-css-prefix}-joget:before {
    content: fa-content($fa-var-joget);
  }
}
@mixin fa-joint {
  .#{$fa-css-prefix}-joint:before {
    content: fa-content($fa-var-joint);
  }
}
@mixin fa-joomla {
  .#{$fa-css-prefix}-joomla:before {
    content: fa-content($fa-var-joomla);
  }
}
@mixin fa-journal-whills {
  .#{$fa-css-prefix}-journal-whills:before {
    content: fa-content($fa-var-journal-whills);
  }
}
@mixin fa-js {
  .#{$fa-css-prefix}-js:before {
    content: fa-content($fa-var-js);
  }
}
@mixin fa-js-square {
  .#{$fa-css-prefix}-js-square:before {
    content: fa-content($fa-var-js-square);
  }
}
@mixin fa-jsfiddle {
  .#{$fa-css-prefix}-jsfiddle:before {
    content: fa-content($fa-var-jsfiddle);
  }
}
@mixin fa-kaaba {
  .#{$fa-css-prefix}-kaaba:before {
    content: fa-content($fa-var-kaaba);
  }
}
@mixin fa-kaggle {
  .#{$fa-css-prefix}-kaggle:before {
    content: fa-content($fa-var-kaggle);
  }
}
@mixin fa-key {
  .#{$fa-css-prefix}-key:before {
    content: fa-content($fa-var-key);
  }
}
@mixin fa-keybase {
  .#{$fa-css-prefix}-keybase:before {
    content: fa-content($fa-var-keybase);
  }
}
@mixin fa-keyboard {
  .#{$fa-css-prefix}-keyboard:before {
    content: fa-content($fa-var-keyboard);
  }
}
@mixin fa-keycdn {
  .#{$fa-css-prefix}-keycdn:before {
    content: fa-content($fa-var-keycdn);
  }
}
@mixin fa-khanda {
  .#{$fa-css-prefix}-khanda:before {
    content: fa-content($fa-var-khanda);
  }
}
@mixin fa-kickstarter {
  .#{$fa-css-prefix}-kickstarter:before {
    content: fa-content($fa-var-kickstarter);
  }
}
@mixin fa-kickstarter-k {
  .#{$fa-css-prefix}-kickstarter-k:before {
    content: fa-content($fa-var-kickstarter-k);
  }
}
@mixin fa-kiss {
  .#{$fa-css-prefix}-kiss:before {
    content: fa-content($fa-var-kiss);
  }
}
@mixin fa-kiss-beam {
  .#{$fa-css-prefix}-kiss-beam:before {
    content: fa-content($fa-var-kiss-beam);
  }
}
@mixin fa-kiss-wink-heart {
  .#{$fa-css-prefix}-kiss-wink-heart:before {
    content: fa-content($fa-var-kiss-wink-heart);
  }
}
@mixin fa-kiwi-bird {
  .#{$fa-css-prefix}-kiwi-bird:before {
    content: fa-content($fa-var-kiwi-bird);
  }
}
@mixin fa-korvue {
  .#{$fa-css-prefix}-korvue:before {
    content: fa-content($fa-var-korvue);
  }
}
@mixin fa-landmark {
  .#{$fa-css-prefix}-landmark:before {
    content: fa-content($fa-var-landmark);
  }
}
@mixin fa-language {
  .#{$fa-css-prefix}-language:before {
    content: fa-content($fa-var-language);
  }
}
@mixin fa-laptop {
  .#{$fa-css-prefix}-laptop:before {
    content: fa-content($fa-var-laptop);
  }
}
@mixin fa-laptop-code {
  .#{$fa-css-prefix}-laptop-code:before {
    content: fa-content($fa-var-laptop-code);
  }
}
@mixin fa-laravel {
  .#{$fa-css-prefix}-laravel:before {
    content: fa-content($fa-var-laravel);
  }
}
@mixin fa-lastfm {
  .#{$fa-css-prefix}-lastfm:before {
    content: fa-content($fa-var-lastfm);
  }
}
@mixin fa-lastfm-square {
  .#{$fa-css-prefix}-lastfm-square:before {
    content: fa-content($fa-var-lastfm-square);
  }
}
@mixin fa-laugh {
  .#{$fa-css-prefix}-laugh:before {
    content: fa-content($fa-var-laugh);
  }
}
@mixin fa-laugh-beam {
  .#{$fa-css-prefix}-laugh-beam:before {
    content: fa-content($fa-var-laugh-beam);
  }
}
@mixin fa-laugh-squint {
  .#{$fa-css-prefix}-laugh-squint:before {
    content: fa-content($fa-var-laugh-squint);
  }
}
@mixin fa-laugh-wink {
  .#{$fa-css-prefix}-laugh-wink:before {
    content: fa-content($fa-var-laugh-wink);
  }
}
@mixin fa-layer-group {
  .#{$fa-css-prefix}-layer-group:before {
    content: fa-content($fa-var-layer-group);
  }
}
@mixin fa-leaf {
  .#{$fa-css-prefix}-leaf:before {
    content: fa-content($fa-var-leaf);
  }
}
@mixin fa-leanpub {
  .#{$fa-css-prefix}-leanpub:before {
    content: fa-content($fa-var-leanpub);
  }
}
@mixin fa-lemon {
  .#{$fa-css-prefix}-lemon:before {
    content: fa-content($fa-var-lemon);
  }
}
@mixin fa-less {
  .#{$fa-css-prefix}-less:before {
    content: fa-content($fa-var-less);
  }
}
@mixin fa-less-than {
  .#{$fa-css-prefix}-less-than:before {
    content: fa-content($fa-var-less-than);
  }
}
@mixin fa-less-than-equal {
  .#{$fa-css-prefix}-less-than-equal:before {
    content: fa-content($fa-var-less-than-equal);
  }
}
@mixin fa-level-down-alt {
  .#{$fa-css-prefix}-level-down-alt:before {
    content: fa-content($fa-var-level-down-alt);
  }
}
@mixin fa-level-up-alt {
  .#{$fa-css-prefix}-level-up-alt:before {
    content: fa-content($fa-var-level-up-alt);
  }
}
@mixin fa-life-ring {
  .#{$fa-css-prefix}-life-ring:before {
    content: fa-content($fa-var-life-ring);
  }
}
@mixin fa-lightbulb {
  .#{$fa-css-prefix}-lightbulb:before {
    content: fa-content($fa-var-lightbulb);
  }
}
@mixin fa-line {
  .#{$fa-css-prefix}-line:before {
    content: fa-content($fa-var-line);
  }
}
@mixin fa-link {
  .#{$fa-css-prefix}-link:before {
    content: fa-content($fa-var-link);
  }
}
@mixin fa-linkedin {
  .#{$fa-css-prefix}-linkedin:before {
    content: fa-content($fa-var-linkedin);
  }
}
@mixin fa-linkedin-in {
  .#{$fa-css-prefix}-linkedin-in:before {
    content: fa-content($fa-var-linkedin-in);
  }
}
@mixin fa-linode {
  .#{$fa-css-prefix}-linode:before {
    content: fa-content($fa-var-linode);
  }
}
@mixin fa-linux {
  .#{$fa-css-prefix}-linux:before {
    content: fa-content($fa-var-linux);
  }
}
@mixin fa-lira-sign {
  .#{$fa-css-prefix}-lira-sign:before {
    content: fa-content($fa-var-lira-sign);
  }
}
@mixin fa-list {
  .#{$fa-css-prefix}-list:before {
    content: fa-content($fa-var-list);
  }
}
@mixin fa-list-alt {
  .#{$fa-css-prefix}-list-alt:before {
    content: fa-content($fa-var-list-alt);
  }
}
@mixin fa-list-ol {
  .#{$fa-css-prefix}-list-ol:before {
    content: fa-content($fa-var-list-ol);
  }
}
@mixin fa-list-ul {
  .#{$fa-css-prefix}-list-ul:before {
    content: fa-content($fa-var-list-ul);
  }
}
@mixin fa-location-arrow {
  .#{$fa-css-prefix}-location-arrow:before {
    content: fa-content($fa-var-location-arrow);
  }
}
@mixin fa-lock {
  .#{$fa-css-prefix}-lock:before {
    content: fa-content($fa-var-lock);
  }
}
@mixin fa-lock-open {
  .#{$fa-css-prefix}-lock-open:before {
    content: fa-content($fa-var-lock-open);
  }
}
@mixin fa-long-arrow-alt-down {
  .#{$fa-css-prefix}-long-arrow-alt-down:before {
    content: fa-content($fa-var-long-arrow-alt-down);
  }
}
@mixin fa-long-arrow-alt-left {
  .#{$fa-css-prefix}-long-arrow-alt-left:before {
    content: fa-content($fa-var-long-arrow-alt-left);
  }
}
@mixin fa-long-arrow-alt-right {
  .#{$fa-css-prefix}-long-arrow-alt-right:before {
    content: fa-content($fa-var-long-arrow-alt-right);
  }
}
@mixin fa-long-arrow-alt-up {
  .#{$fa-css-prefix}-long-arrow-alt-up:before {
    content: fa-content($fa-var-long-arrow-alt-up);
  }
}
@mixin fa-low-vision {
  .#{$fa-css-prefix}-low-vision:before {
    content: fa-content($fa-var-low-vision);
  }
}
@mixin fa-luggage-cart {
  .#{$fa-css-prefix}-luggage-cart:before {
    content: fa-content($fa-var-luggage-cart);
  }
}
@mixin fa-lyft {
  .#{$fa-css-prefix}-lyft:before {
    content: fa-content($fa-var-lyft);
  }
}
@mixin fa-magento {
  .#{$fa-css-prefix}-magento:before {
    content: fa-content($fa-var-magento);
  }
}
@mixin fa-magic {
  .#{$fa-css-prefix}-magic:before {
    content: fa-content($fa-var-magic);
  }
}
@mixin fa-magnet {
  .#{$fa-css-prefix}-magnet:before {
    content: fa-content($fa-var-magnet);
  }
}
@mixin fa-mail-bulk {
  .#{$fa-css-prefix}-mail-bulk:before {
    content: fa-content($fa-var-mail-bulk);
  }
}
@mixin fa-mailchimp {
  .#{$fa-css-prefix}-mailchimp:before {
    content: fa-content($fa-var-mailchimp);
  }
}
@mixin fa-male {
  .#{$fa-css-prefix}-male:before {
    content: fa-content($fa-var-male);
  }
}
@mixin fa-mandalorian {
  .#{$fa-css-prefix}-mandalorian:before {
    content: fa-content($fa-var-mandalorian);
  }
}
@mixin fa-map {
  .#{$fa-css-prefix}-map:before {
    content: fa-content($fa-var-map);
  }
}
@mixin fa-map-marked {
  .#{$fa-css-prefix}-map-marked:before {
    content: fa-content($fa-var-map-marked);
  }
}
@mixin fa-map-marked-alt {
  .#{$fa-css-prefix}-map-marked-alt:before {
    content: fa-content($fa-var-map-marked-alt);
  }
}
@mixin fa-map-marker {
  .#{$fa-css-prefix}-map-marker:before {
    content: fa-content($fa-var-map-marker);
  }
}
@mixin fa-map-marker-alt {
  .#{$fa-css-prefix}-map-marker-alt:before {
    content: fa-content($fa-var-map-marker-alt);
  }
}
@mixin fa-map-pin {
  .#{$fa-css-prefix}-map-pin:before {
    content: fa-content($fa-var-map-pin);
  }
}
@mixin fa-map-signs {
  .#{$fa-css-prefix}-map-signs:before {
    content: fa-content($fa-var-map-signs);
  }
}
@mixin fa-markdown {
  .#{$fa-css-prefix}-markdown:before {
    content: fa-content($fa-var-markdown);
  }
}
@mixin fa-marker {
  .#{$fa-css-prefix}-marker:before {
    content: fa-content($fa-var-marker);
  }
}
@mixin fa-mars {
  .#{$fa-css-prefix}-mars:before {
    content: fa-content($fa-var-mars);
  }
}
@mixin fa-mars-double {
  .#{$fa-css-prefix}-mars-double:before {
    content: fa-content($fa-var-mars-double);
  }
}
@mixin fa-mars-stroke {
  .#{$fa-css-prefix}-mars-stroke:before {
    content: fa-content($fa-var-mars-stroke);
  }
}
@mixin fa-mars-stroke-h {
  .#{$fa-css-prefix}-mars-stroke-h:before {
    content: fa-content($fa-var-mars-stroke-h);
  }
}
@mixin fa-mars-stroke-v {
  .#{$fa-css-prefix}-mars-stroke-v:before {
    content: fa-content($fa-var-mars-stroke-v);
  }
}
@mixin fa-mastodon {
  .#{$fa-css-prefix}-mastodon:before {
    content: fa-content($fa-var-mastodon);
  }
}
@mixin fa-maxcdn {
  .#{$fa-css-prefix}-maxcdn:before {
    content: fa-content($fa-var-maxcdn);
  }
}
@mixin fa-medal {
  .#{$fa-css-prefix}-medal:before {
    content: fa-content($fa-var-medal);
  }
}
@mixin fa-medapps {
  .#{$fa-css-prefix}-medapps:before {
    content: fa-content($fa-var-medapps);
  }
}
@mixin fa-medium {
  .#{$fa-css-prefix}-medium:before {
    content: fa-content($fa-var-medium);
  }
}
@mixin fa-medium-m {
  .#{$fa-css-prefix}-medium-m:before {
    content: fa-content($fa-var-medium-m);
  }
}
@mixin fa-medkit {
  .#{$fa-css-prefix}-medkit:before {
    content: fa-content($fa-var-medkit);
  }
}
@mixin fa-medrt {
  .#{$fa-css-prefix}-medrt:before {
    content: fa-content($fa-var-medrt);
  }
}
@mixin fa-meetup {
  .#{$fa-css-prefix}-meetup:before {
    content: fa-content($fa-var-meetup);
  }
}
@mixin fa-megaport {
  .#{$fa-css-prefix}-megaport:before {
    content: fa-content($fa-var-megaport);
  }
}
@mixin fa-meh {
  .#{$fa-css-prefix}-meh:before {
    content: fa-content($fa-var-meh);
  }
}
@mixin fa-meh-blank {
  .#{$fa-css-prefix}-meh-blank:before {
    content: fa-content($fa-var-meh-blank);
  }
}
@mixin fa-meh-rolling-eyes {
  .#{$fa-css-prefix}-meh-rolling-eyes:before {
    content: fa-content($fa-var-meh-rolling-eyes);
  }
}
@mixin fa-memory {
  .#{$fa-css-prefix}-memory:before {
    content: fa-content($fa-var-memory);
  }
}
@mixin fa-menorah {
  .#{$fa-css-prefix}-menorah:before {
    content: fa-content($fa-var-menorah);
  }
}
@mixin fa-mercury {
  .#{$fa-css-prefix}-mercury:before {
    content: fa-content($fa-var-mercury);
  }
}
@mixin fa-microchip {
  .#{$fa-css-prefix}-microchip:before {
    content: fa-content($fa-var-microchip);
  }
}
@mixin fa-microphone {
  .#{$fa-css-prefix}-microphone:before {
    content: fa-content($fa-var-microphone);
  }
}
@mixin fa-microphone-alt {
  .#{$fa-css-prefix}-microphone-alt:before {
    content: fa-content($fa-var-microphone-alt);
  }
}
@mixin fa-microphone-alt-slash {
  .#{$fa-css-prefix}-microphone-alt-slash:before {
    content: fa-content($fa-var-microphone-alt-slash);
  }
}
@mixin fa-microphone-slash {
  .#{$fa-css-prefix}-microphone-slash:before {
    content: fa-content($fa-var-microphone-slash);
  }
}
@mixin fa-microscope {
  .#{$fa-css-prefix}-microscope:before {
    content: fa-content($fa-var-microscope);
  }
}
@mixin fa-microsoft {
  .#{$fa-css-prefix}-microsoft:before {
    content: fa-content($fa-var-microsoft);
  }
}
@mixin fa-minus {
  .#{$fa-css-prefix}-minus:before {
    content: fa-content($fa-var-minus);
  }
}
@mixin fa-minus-circle {
  .#{$fa-css-prefix}-minus-circle:before {
    content: fa-content($fa-var-minus-circle);
  }
}
@mixin fa-minus-square {
  .#{$fa-css-prefix}-minus-square:before {
    content: fa-content($fa-var-minus-square);
  }
}
@mixin fa-mix {
  .#{$fa-css-prefix}-mix:before {
    content: fa-content($fa-var-mix);
  }
}
@mixin fa-mixcloud {
  .#{$fa-css-prefix}-mixcloud:before {
    content: fa-content($fa-var-mixcloud);
  }
}
@mixin fa-mizuni {
  .#{$fa-css-prefix}-mizuni:before {
    content: fa-content($fa-var-mizuni);
  }
}
@mixin fa-mobile {
  .#{$fa-css-prefix}-mobile:before {
    content: fa-content($fa-var-mobile);
  }
}
@mixin fa-mobile-alt {
  .#{$fa-css-prefix}-mobile-alt:before {
    content: fa-content($fa-var-mobile-alt);
  }
}
@mixin fa-modx {
  .#{$fa-css-prefix}-modx:before {
    content: fa-content($fa-var-modx);
  }
}
@mixin fa-monero {
  .#{$fa-css-prefix}-monero:before {
    content: fa-content($fa-var-monero);
  }
}
@mixin fa-money-bill {
  .#{$fa-css-prefix}-money-bill:before {
    content: fa-content($fa-var-money-bill);
  }
}
@mixin fa-money-bill-alt {
  .#{$fa-css-prefix}-money-bill-alt:before {
    content: fa-content($fa-var-money-bill-alt);
  }
}
@mixin fa-money-bill-wave {
  .#{$fa-css-prefix}-money-bill-wave:before {
    content: fa-content($fa-var-money-bill-wave);
  }
}
@mixin fa-money-bill-wave-alt {
  .#{$fa-css-prefix}-money-bill-wave-alt:before {
    content: fa-content($fa-var-money-bill-wave-alt);
  }
}
@mixin fa-money-check {
  .#{$fa-css-prefix}-money-check:before {
    content: fa-content($fa-var-money-check);
  }
}
@mixin fa-money-check-alt {
  .#{$fa-css-prefix}-money-check-alt:before {
    content: fa-content($fa-var-money-check-alt);
  }
}
@mixin fa-monument {
  .#{$fa-css-prefix}-monument:before {
    content: fa-content($fa-var-monument);
  }
}
@mixin fa-moon {
  .#{$fa-css-prefix}-moon:before {
    content: fa-content($fa-var-moon);
  }
}
@mixin fa-mortar-pestle {
  .#{$fa-css-prefix}-mortar-pestle:before {
    content: fa-content($fa-var-mortar-pestle);
  }
}
@mixin fa-mosque {
  .#{$fa-css-prefix}-mosque:before {
    content: fa-content($fa-var-mosque);
  }
}
@mixin fa-motorcycle {
  .#{$fa-css-prefix}-motorcycle:before {
    content: fa-content($fa-var-motorcycle);
  }
}
@mixin fa-mouse-pointer {
  .#{$fa-css-prefix}-mouse-pointer:before {
    content: fa-content($fa-var-mouse-pointer);
  }
}
@mixin fa-music {
  .#{$fa-css-prefix}-music:before {
    content: fa-content($fa-var-music);
  }
}
@mixin fa-napster {
  .#{$fa-css-prefix}-napster:before {
    content: fa-content($fa-var-napster);
  }
}
@mixin fa-neos {
  .#{$fa-css-prefix}-neos:before {
    content: fa-content($fa-var-neos);
  }
}
@mixin fa-neuter {
  .#{$fa-css-prefix}-neuter:before {
    content: fa-content($fa-var-neuter);
  }
}
@mixin fa-newspaper {
  .#{$fa-css-prefix}-newspaper:before {
    content: fa-content($fa-var-newspaper);
  }
}
@mixin fa-nimblr {
  .#{$fa-css-prefix}-nimblr:before {
    content: fa-content($fa-var-nimblr);
  }
}
@mixin fa-nintendo-switch {
  .#{$fa-css-prefix}-nintendo-switch:before {
    content: fa-content($fa-var-nintendo-switch);
  }
}
@mixin fa-node {
  .#{$fa-css-prefix}-node:before {
    content: fa-content($fa-var-node);
  }
}
@mixin fa-node-js {
  .#{$fa-css-prefix}-node-js:before {
    content: fa-content($fa-var-node-js);
  }
}
@mixin fa-not-equal {
  .#{$fa-css-prefix}-not-equal:before {
    content: fa-content($fa-var-not-equal);
  }
}
@mixin fa-notes-medical {
  .#{$fa-css-prefix}-notes-medical:before {
    content: fa-content($fa-var-notes-medical);
  }
}
@mixin fa-npm {
  .#{$fa-css-prefix}-npm:before {
    content: fa-content($fa-var-npm);
  }
}
@mixin fa-ns8 {
  .#{$fa-css-prefix}-ns8:before {
    content: fa-content($fa-var-ns8);
  }
}
@mixin fa-nutritionix {
  .#{$fa-css-prefix}-nutritionix:before {
    content: fa-content($fa-var-nutritionix);
  }
}
@mixin fa-object-group {
  .#{$fa-css-prefix}-object-group:before {
    content: fa-content($fa-var-object-group);
  }
}
@mixin fa-object-ungroup {
  .#{$fa-css-prefix}-object-ungroup:before {
    content: fa-content($fa-var-object-ungroup);
  }
}
@mixin fa-odnoklassniki {
  .#{$fa-css-prefix}-odnoklassniki:before {
    content: fa-content($fa-var-odnoklassniki);
  }
}
@mixin fa-odnoklassniki-square {
  .#{$fa-css-prefix}-odnoklassniki-square:before {
    content: fa-content($fa-var-odnoklassniki-square);
  }
}
@mixin fa-oil-can {
  .#{$fa-css-prefix}-oil-can:before {
    content: fa-content($fa-var-oil-can);
  }
}
@mixin fa-old-republic {
  .#{$fa-css-prefix}-old-republic:before {
    content: fa-content($fa-var-old-republic);
  }
}
@mixin fa-om {
  .#{$fa-css-prefix}-om:before {
    content: fa-content($fa-var-om);
  }
}
@mixin fa-opencart {
  .#{$fa-css-prefix}-opencart:before {
    content: fa-content($fa-var-opencart);
  }
}
@mixin fa-openid {
  .#{$fa-css-prefix}-openid:before {
    content: fa-content($fa-var-openid);
  }
}
@mixin fa-opera {
  .#{$fa-css-prefix}-opera:before {
    content: fa-content($fa-var-opera);
  }
}
@mixin fa-optin-monster {
  .#{$fa-css-prefix}-optin-monster:before {
    content: fa-content($fa-var-optin-monster);
  }
}
@mixin fa-osi {
  .#{$fa-css-prefix}-osi:before {
    content: fa-content($fa-var-osi);
  }
}
@mixin fa-outdent {
  .#{$fa-css-prefix}-outdent:before {
    content: fa-content($fa-var-outdent);
  }
}
@mixin fa-page4 {
  .#{$fa-css-prefix}-page4:before {
    content: fa-content($fa-var-page4);
  }
}
@mixin fa-pagelines {
  .#{$fa-css-prefix}-pagelines:before {
    content: fa-content($fa-var-pagelines);
  }
}
@mixin fa-paint-brush {
  .#{$fa-css-prefix}-paint-brush:before {
    content: fa-content($fa-var-paint-brush);
  }
}
@mixin fa-paint-roller {
  .#{$fa-css-prefix}-paint-roller:before {
    content: fa-content($fa-var-paint-roller);
  }
}
@mixin fa-palette {
  .#{$fa-css-prefix}-palette:before {
    content: fa-content($fa-var-palette);
  }
}
@mixin fa-palfed {
  .#{$fa-css-prefix}-palfed:before {
    content: fa-content($fa-var-palfed);
  }
}
@mixin fa-pallet {
  .#{$fa-css-prefix}-pallet:before {
    content: fa-content($fa-var-pallet);
  }
}
@mixin fa-paper-plane {
  .#{$fa-css-prefix}-paper-plane:before {
    content: fa-content($fa-var-paper-plane);
  }
}
@mixin fa-paperclip {
  .#{$fa-css-prefix}-paperclip:before {
    content: fa-content($fa-var-paperclip);
  }
}
@mixin fa-parachute-box {
  .#{$fa-css-prefix}-parachute-box:before {
    content: fa-content($fa-var-parachute-box);
  }
}
@mixin fa-paragraph {
  .#{$fa-css-prefix}-paragraph:before {
    content: fa-content($fa-var-paragraph);
  }
}
@mixin fa-parking {
  .#{$fa-css-prefix}-parking:before {
    content: fa-content($fa-var-parking);
  }
}
@mixin fa-passport {
  .#{$fa-css-prefix}-passport:before {
    content: fa-content($fa-var-passport);
  }
}
@mixin fa-pastafarianism {
  .#{$fa-css-prefix}-pastafarianism:before {
    content: fa-content($fa-var-pastafarianism);
  }
}
@mixin fa-paste {
  .#{$fa-css-prefix}-paste:before {
    content: fa-content($fa-var-paste);
  }
}
@mixin fa-patreon {
  .#{$fa-css-prefix}-patreon:before {
    content: fa-content($fa-var-patreon);
  }
}
@mixin fa-pause {
  .#{$fa-css-prefix}-pause:before {
    content: fa-content($fa-var-pause);
  }
}
@mixin fa-pause-circle {
  .#{$fa-css-prefix}-pause-circle:before {
    content: fa-content($fa-var-pause-circle);
  }
}
@mixin fa-paw {
  .#{$fa-css-prefix}-paw:before {
    content: fa-content($fa-var-paw);
  }
}
@mixin fa-paypal {
  .#{$fa-css-prefix}-paypal:before {
    content: fa-content($fa-var-paypal);
  }
}
@mixin fa-peace {
  .#{$fa-css-prefix}-peace:before {
    content: fa-content($fa-var-peace);
  }
}
@mixin fa-pen {
  .#{$fa-css-prefix}-pen:before {
    content: fa-content($fa-var-pen);
  }
}
@mixin fa-pen-alt {
  .#{$fa-css-prefix}-pen-alt:before {
    content: fa-content($fa-var-pen-alt);
  }
}
@mixin fa-pen-fancy {
  .#{$fa-css-prefix}-pen-fancy:before {
    content: fa-content($fa-var-pen-fancy);
  }
}
@mixin fa-pen-nib {
  .#{$fa-css-prefix}-pen-nib:before {
    content: fa-content($fa-var-pen-nib);
  }
}
@mixin fa-pen-square {
  .#{$fa-css-prefix}-pen-square:before {
    content: fa-content($fa-var-pen-square);
  }
}
@mixin fa-pencil-alt {
  .#{$fa-css-prefix}-pencil-alt:before {
    content: fa-content($fa-var-pencil-alt);
  }
}
@mixin fa-pencil-ruler {
  .#{$fa-css-prefix}-pencil-ruler:before {
    content: fa-content($fa-var-pencil-ruler);
  }
}
@mixin fa-people-carry {
  .#{$fa-css-prefix}-people-carry:before {
    content: fa-content($fa-var-people-carry);
  }
}
@mixin fa-percent {
  .#{$fa-css-prefix}-percent:before {
    content: fa-content($fa-var-percent);
  }
}
@mixin fa-percentage {
  .#{$fa-css-prefix}-percentage:before {
    content: fa-content($fa-var-percentage);
  }
}
@mixin fa-periscope {
  .#{$fa-css-prefix}-periscope:before {
    content: fa-content($fa-var-periscope);
  }
}
@mixin fa-phabricator {
  .#{$fa-css-prefix}-phabricator:before {
    content: fa-content($fa-var-phabricator);
  }
}
@mixin fa-phoenix-framework {
  .#{$fa-css-prefix}-phoenix-framework:before {
    content: fa-content($fa-var-phoenix-framework);
  }
}
@mixin fa-phoenix-squadron {
  .#{$fa-css-prefix}-phoenix-squadron:before {
    content: fa-content($fa-var-phoenix-squadron);
  }
}
@mixin fa-phone {
  .#{$fa-css-prefix}-phone:before {
    content: fa-content($fa-var-phone);
  }
}
@mixin fa-phone-slash {
  .#{$fa-css-prefix}-phone-slash:before {
    content: fa-content($fa-var-phone-slash);
  }
}
@mixin fa-phone-square {
  .#{$fa-css-prefix}-phone-square:before {
    content: fa-content($fa-var-phone-square);
  }
}
@mixin fa-phone-volume {
  .#{$fa-css-prefix}-phone-volume:before {
    content: fa-content($fa-var-phone-volume);
  }
}
@mixin fa-php {
  .#{$fa-css-prefix}-php:before {
    content: fa-content($fa-var-php);
  }
}
@mixin fa-pied-piper {
  .#{$fa-css-prefix}-pied-piper:before {
    content: fa-content($fa-var-pied-piper);
  }
}
@mixin fa-pied-piper-alt {
  .#{$fa-css-prefix}-pied-piper-alt:before {
    content: fa-content($fa-var-pied-piper-alt);
  }
}
@mixin fa-pied-piper-hat {
  .#{$fa-css-prefix}-pied-piper-hat:before {
    content: fa-content($fa-var-pied-piper-hat);
  }
}
@mixin fa-pied-piper-pp {
  .#{$fa-css-prefix}-pied-piper-pp:before {
    content: fa-content($fa-var-pied-piper-pp);
  }
}
@mixin fa-piggy-bank {
  .#{$fa-css-prefix}-piggy-bank:before {
    content: fa-content($fa-var-piggy-bank);
  }
}
@mixin fa-pills {
  .#{$fa-css-prefix}-pills:before {
    content: fa-content($fa-var-pills);
  }
}
@mixin fa-pinterest {
  .#{$fa-css-prefix}-pinterest:before {
    content: fa-content($fa-var-pinterest);
  }
}
@mixin fa-pinterest-p {
  .#{$fa-css-prefix}-pinterest-p:before {
    content: fa-content($fa-var-pinterest-p);
  }
}
@mixin fa-pinterest-square {
  .#{$fa-css-prefix}-pinterest-square:before {
    content: fa-content($fa-var-pinterest-square);
  }
}
@mixin fa-place-of-worship {
  .#{$fa-css-prefix}-place-of-worship:before {
    content: fa-content($fa-var-place-of-worship);
  }
}
@mixin fa-plane {
  .#{$fa-css-prefix}-plane:before {
    content: fa-content($fa-var-plane);
  }
}
@mixin fa-plane-arrival {
  .#{$fa-css-prefix}-plane-arrival:before {
    content: fa-content($fa-var-plane-arrival);
  }
}
@mixin fa-plane-departure {
  .#{$fa-css-prefix}-plane-departure:before {
    content: fa-content($fa-var-plane-departure);
  }
}
@mixin fa-play {
  .#{$fa-css-prefix}-play:before {
    content: fa-content($fa-var-play);
  }
}
@mixin fa-play-circle {
  .#{$fa-css-prefix}-play-circle:before {
    content: fa-content($fa-var-play-circle);
  }
}
@mixin fa-playstation {
  .#{$fa-css-prefix}-playstation:before {
    content: fa-content($fa-var-playstation);
  }
}
@mixin fa-plug {
  .#{$fa-css-prefix}-plug:before {
    content: fa-content($fa-var-plug);
  }
}
@mixin fa-plus {
  .#{$fa-css-prefix}-plus:before {
    content: fa-content($fa-var-plus);
  }
}
@mixin fa-plus-circle {
  .#{$fa-css-prefix}-plus-circle:before {
    content: fa-content($fa-var-plus-circle);
  }
}
@mixin fa-plus-square {
  .#{$fa-css-prefix}-plus-square:before {
    content: fa-content($fa-var-plus-square);
  }
}
@mixin fa-podcast {
  .#{$fa-css-prefix}-podcast:before {
    content: fa-content($fa-var-podcast);
  }
}
@mixin fa-poll {
  .#{$fa-css-prefix}-poll:before {
    content: fa-content($fa-var-poll);
  }
}
@mixin fa-poll-h {
  .#{$fa-css-prefix}-poll-h:before {
    content: fa-content($fa-var-poll-h);
  }
}
@mixin fa-poo {
  .#{$fa-css-prefix}-poo:before {
    content: fa-content($fa-var-poo);
  }
}
@mixin fa-poop {
  .#{$fa-css-prefix}-poop:before {
    content: fa-content($fa-var-poop);
  }
}
@mixin fa-portrait {
  .#{$fa-css-prefix}-portrait:before {
    content: fa-content($fa-var-portrait);
  }
}
@mixin fa-pound-sign {
  .#{$fa-css-prefix}-pound-sign:before {
    content: fa-content($fa-var-pound-sign);
  }
}
@mixin fa-power-off {
  .#{$fa-css-prefix}-power-off:before {
    content: fa-content($fa-var-power-off);
  }
}
@mixin fa-pray {
  .#{$fa-css-prefix}-pray:before {
    content: fa-content($fa-var-pray);
  }
}
@mixin fa-praying-hands {
  .#{$fa-css-prefix}-praying-hands:before {
    content: fa-content($fa-var-praying-hands);
  }
}
@mixin fa-prescription {
  .#{$fa-css-prefix}-prescription:before {
    content: fa-content($fa-var-prescription);
  }
}
@mixin fa-prescription-bottle {
  .#{$fa-css-prefix}-prescription-bottle:before {
    content: fa-content($fa-var-prescription-bottle);
  }
}
@mixin fa-prescription-bottle-alt {
  .#{$fa-css-prefix}-prescription-bottle-alt:before {
    content: fa-content($fa-var-prescription-bottle-alt);
  }
}
@mixin fa-print {
  .#{$fa-css-prefix}-print:before {
    content: fa-content($fa-var-print);
  }
}
@mixin fa-procedures {
  .#{$fa-css-prefix}-procedures:before {
    content: fa-content($fa-var-procedures);
  }
}
@mixin fa-product-hunt {
  .#{$fa-css-prefix}-product-hunt:before {
    content: fa-content($fa-var-product-hunt);
  }
}
@mixin fa-project-diagram {
  .#{$fa-css-prefix}-project-diagram:before {
    content: fa-content($fa-var-project-diagram);
  }
}
@mixin fa-pushed {
  .#{$fa-css-prefix}-pushed:before {
    content: fa-content($fa-var-pushed);
  }
}
@mixin fa-puzzle-piece {
  .#{$fa-css-prefix}-puzzle-piece:before {
    content: fa-content($fa-var-puzzle-piece);
  }
}
@mixin fa-python {
  .#{$fa-css-prefix}-python:before {
    content: fa-content($fa-var-python);
  }
}
@mixin fa-qq {
  .#{$fa-css-prefix}-qq:before {
    content: fa-content($fa-var-qq);
  }
}
@mixin fa-qrcode {
  .#{$fa-css-prefix}-qrcode:before {
    content: fa-content($fa-var-qrcode);
  }
}
@mixin fa-question {
  .#{$fa-css-prefix}-question:before {
    content: fa-content($fa-var-question);
  }
}
@mixin fa-question-circle {
  .#{$fa-css-prefix}-question-circle:before {
    content: fa-content($fa-var-question-circle);
  }
}
@mixin fa-quidditch {
  .#{$fa-css-prefix}-quidditch:before {
    content: fa-content($fa-var-quidditch);
  }
}
@mixin fa-quinscape {
  .#{$fa-css-prefix}-quinscape:before {
    content: fa-content($fa-var-quinscape);
  }
}
@mixin fa-quora {
  .#{$fa-css-prefix}-quora:before {
    content: fa-content($fa-var-quora);
  }
}
@mixin fa-quote-left {
  .#{$fa-css-prefix}-quote-left:before {
    content: fa-content($fa-var-quote-left);
  }
}
@mixin fa-quote-right {
  .#{$fa-css-prefix}-quote-right:before {
    content: fa-content($fa-var-quote-right);
  }
}
@mixin fa-quran {
  .#{$fa-css-prefix}-quran:before {
    content: fa-content($fa-var-quran);
  }
}
@mixin fa-r-project {
  .#{$fa-css-prefix}-r-project:before {
    content: fa-content($fa-var-r-project);
  }
}
@mixin fa-random {
  .#{$fa-css-prefix}-random:before {
    content: fa-content($fa-var-random);
  }
}
@mixin fa-ravelry {
  .#{$fa-css-prefix}-ravelry:before {
    content: fa-content($fa-var-ravelry);
  }
}
@mixin fa-react {
  .#{$fa-css-prefix}-react:before {
    content: fa-content($fa-var-react);
  }
}
@mixin fa-readme {
  .#{$fa-css-prefix}-readme:before {
    content: fa-content($fa-var-readme);
  }
}
@mixin fa-rebel {
  .#{$fa-css-prefix}-rebel:before {
    content: fa-content($fa-var-rebel);
  }
}
@mixin fa-receipt {
  .#{$fa-css-prefix}-receipt:before {
    content: fa-content($fa-var-receipt);
  }
}
@mixin fa-recycle {
  .#{$fa-css-prefix}-recycle:before {
    content: fa-content($fa-var-recycle);
  }
}
@mixin fa-red-river {
  .#{$fa-css-prefix}-red-river:before {
    content: fa-content($fa-var-red-river);
  }
}
@mixin fa-reddit {
  .#{$fa-css-prefix}-reddit:before {
    content: fa-content($fa-var-reddit);
  }
}
@mixin fa-reddit-alien {
  .#{$fa-css-prefix}-reddit-alien:before {
    content: fa-content($fa-var-reddit-alien);
  }
}
@mixin fa-reddit-square {
  .#{$fa-css-prefix}-reddit-square:before {
    content: fa-content($fa-var-reddit-square);
  }
}
@mixin fa-redo {
  .#{$fa-css-prefix}-redo:before {
    content: fa-content($fa-var-redo);
  }
}
@mixin fa-redo-alt {
  .#{$fa-css-prefix}-redo-alt:before {
    content: fa-content($fa-var-redo-alt);
  }
}
@mixin fa-registered {
  .#{$fa-css-prefix}-registered:before {
    content: fa-content($fa-var-registered);
  }
}
@mixin fa-rendact {
  .#{$fa-css-prefix}-rendact:before {
    content: fa-content($fa-var-rendact);
  }
}
@mixin fa-renren {
  .#{$fa-css-prefix}-renren:before {
    content: fa-content($fa-var-renren);
  }
}
@mixin fa-reply {
  .#{$fa-css-prefix}-reply:before {
    content: fa-content($fa-var-reply);
  }
}
@mixin fa-reply-all {
  .#{$fa-css-prefix}-reply-all:before {
    content: fa-content($fa-var-reply-all);
  }
}
@mixin fa-replyd {
  .#{$fa-css-prefix}-replyd:before {
    content: fa-content($fa-var-replyd);
  }
}
@mixin fa-researchgate {
  .#{$fa-css-prefix}-researchgate:before {
    content: fa-content($fa-var-researchgate);
  }
}
@mixin fa-resolving {
  .#{$fa-css-prefix}-resolving:before {
    content: fa-content($fa-var-resolving);
  }
}
@mixin fa-retweet {
  .#{$fa-css-prefix}-retweet:before {
    content: fa-content($fa-var-retweet);
  }
}
@mixin fa-rev {
  .#{$fa-css-prefix}-rev:before {
    content: fa-content($fa-var-rev);
  }
}
@mixin fa-ribbon {
  .#{$fa-css-prefix}-ribbon:before {
    content: fa-content($fa-var-ribbon);
  }
}
@mixin fa-road {
  .#{$fa-css-prefix}-road:before {
    content: fa-content($fa-var-road);
  }
}
@mixin fa-robot {
  .#{$fa-css-prefix}-robot:before {
    content: fa-content($fa-var-robot);
  }
}
@mixin fa-rocket {
  .#{$fa-css-prefix}-rocket:before {
    content: fa-content($fa-var-rocket);
  }
}
@mixin fa-rocketchat {
  .#{$fa-css-prefix}-rocketchat:before {
    content: fa-content($fa-var-rocketchat);
  }
}
@mixin fa-rockrms {
  .#{$fa-css-prefix}-rockrms:before {
    content: fa-content($fa-var-rockrms);
  }
}
@mixin fa-route {
  .#{$fa-css-prefix}-route:before {
    content: fa-content($fa-var-route);
  }
}
@mixin fa-rss {
  .#{$fa-css-prefix}-rss:before {
    content: fa-content($fa-var-rss);
  }
}
@mixin fa-rss-square {
  .#{$fa-css-prefix}-rss-square:before {
    content: fa-content($fa-var-rss-square);
  }
}
@mixin fa-ruble-sign {
  .#{$fa-css-prefix}-ruble-sign:before {
    content: fa-content($fa-var-ruble-sign);
  }
}
@mixin fa-ruler {
  .#{$fa-css-prefix}-ruler:before {
    content: fa-content($fa-var-ruler);
  }
}
@mixin fa-ruler-combined {
  .#{$fa-css-prefix}-ruler-combined:before {
    content: fa-content($fa-var-ruler-combined);
  }
}
@mixin fa-ruler-horizontal {
  .#{$fa-css-prefix}-ruler-horizontal:before {
    content: fa-content($fa-var-ruler-horizontal);
  }
}
@mixin fa-ruler-vertical {
  .#{$fa-css-prefix}-ruler-vertical:before {
    content: fa-content($fa-var-ruler-vertical);
  }
}
@mixin fa-rupee-sign {
  .#{$fa-css-prefix}-rupee-sign:before {
    content: fa-content($fa-var-rupee-sign);
  }
}
@mixin fa-sad-cry {
  .#{$fa-css-prefix}-sad-cry:before {
    content: fa-content($fa-var-sad-cry);
  }
}
@mixin fa-sad-tear {
  .#{$fa-css-prefix}-sad-tear:before {
    content: fa-content($fa-var-sad-tear);
  }
}
@mixin fa-safari {
  .#{$fa-css-prefix}-safari:before {
    content: fa-content($fa-var-safari);
  }
}
@mixin fa-sass {
  .#{$fa-css-prefix}-sass:before {
    content: fa-content($fa-var-sass);
  }
}
@mixin fa-save {
  .#{$fa-css-prefix}-save:before {
    content: fa-content($fa-var-save);
  }
}
@mixin fa-schlix {
  .#{$fa-css-prefix}-schlix:before {
    content: fa-content($fa-var-schlix);
  }
}
@mixin fa-school {
  .#{$fa-css-prefix}-school:before {
    content: fa-content($fa-var-school);
  }
}
@mixin fa-screwdriver {
  .#{$fa-css-prefix}-screwdriver:before {
    content: fa-content($fa-var-screwdriver);
  }
}
@mixin fa-scribd {
  .#{$fa-css-prefix}-scribd:before {
    content: fa-content($fa-var-scribd);
  }
}
@mixin fa-search {
  .#{$fa-css-prefix}-search:before {
    content: fa-content($fa-var-search);
  }
}
@mixin fa-search-dollar {
  .#{$fa-css-prefix}-search-dollar:before {
    content: fa-content($fa-var-search-dollar);
  }
}
@mixin fa-search-location {
  .#{$fa-css-prefix}-search-location:before {
    content: fa-content($fa-var-search-location);
  }
}
@mixin fa-search-minus {
  .#{$fa-css-prefix}-search-minus:before {
    content: fa-content($fa-var-search-minus);
  }
}
@mixin fa-search-plus {
  .#{$fa-css-prefix}-search-plus:before {
    content: fa-content($fa-var-search-plus);
  }
}
@mixin fa-searchengin {
  .#{$fa-css-prefix}-searchengin:before {
    content: fa-content($fa-var-searchengin);
  }
}
@mixin fa-seedling {
  .#{$fa-css-prefix}-seedling:before {
    content: fa-content($fa-var-seedling);
  }
}
@mixin fa-sellcast {
  .#{$fa-css-prefix}-sellcast:before {
    content: fa-content($fa-var-sellcast);
  }
}
@mixin fa-sellsy {
  .#{$fa-css-prefix}-sellsy:before {
    content: fa-content($fa-var-sellsy);
  }
}
@mixin fa-server {
  .#{$fa-css-prefix}-server:before {
    content: fa-content($fa-var-server);
  }
}
@mixin fa-servicestack {
  .#{$fa-css-prefix}-servicestack:before {
    content: fa-content($fa-var-servicestack);
  }
}
@mixin fa-shapes {
  .#{$fa-css-prefix}-shapes:before {
    content: fa-content($fa-var-shapes);
  }
}
@mixin fa-share {
  .#{$fa-css-prefix}-share:before {
    content: fa-content($fa-var-share);
  }
}
@mixin fa-share-alt {
  .#{$fa-css-prefix}-share-alt:before {
    content: fa-content($fa-var-share-alt);
  }
}
@mixin fa-share-alt-square {
  .#{$fa-css-prefix}-share-alt-square:before {
    content: fa-content($fa-var-share-alt-square);
  }
}
@mixin fa-share-square {
  .#{$fa-css-prefix}-share-square:before {
    content: fa-content($fa-var-share-square);
  }
}
@mixin fa-shekel-sign {
  .#{$fa-css-prefix}-shekel-sign:before {
    content: fa-content($fa-var-shekel-sign);
  }
}
@mixin fa-shield-alt {
  .#{$fa-css-prefix}-shield-alt:before {
    content: fa-content($fa-var-shield-alt);
  }
}
@mixin fa-ship {
  .#{$fa-css-prefix}-ship:before {
    content: fa-content($fa-var-ship);
  }
}
@mixin fa-shipping-fast {
  .#{$fa-css-prefix}-shipping-fast:before {
    content: fa-content($fa-var-shipping-fast);
  }
}
@mixin fa-shirtsinbulk {
  .#{$fa-css-prefix}-shirtsinbulk:before {
    content: fa-content($fa-var-shirtsinbulk);
  }
}
@mixin fa-shoe-prints {
  .#{$fa-css-prefix}-shoe-prints:before {
    content: fa-content($fa-var-shoe-prints);
  }
}
@mixin fa-shopping-bag {
  .#{$fa-css-prefix}-shopping-bag:before {
    content: fa-content($fa-var-shopping-bag);
  }
}
@mixin fa-shopping-basket {
  .#{$fa-css-prefix}-shopping-basket:before {
    content: fa-content($fa-var-shopping-basket);
  }
}
@mixin fa-shopping-cart {
  .#{$fa-css-prefix}-shopping-cart:before {
    content: fa-content($fa-var-shopping-cart);
  }
}
@mixin fa-shopware {
  .#{$fa-css-prefix}-shopware:before {
    content: fa-content($fa-var-shopware);
  }
}
@mixin fa-shower {
  .#{$fa-css-prefix}-shower:before {
    content: fa-content($fa-var-shower);
  }
}
@mixin fa-shuttle-van {
  .#{$fa-css-prefix}-shuttle-van:before {
    content: fa-content($fa-var-shuttle-van);
  }
}
@mixin fa-sign {
  .#{$fa-css-prefix}-sign:before {
    content: fa-content($fa-var-sign);
  }
}
@mixin fa-sign-in-alt {
  .#{$fa-css-prefix}-sign-in-alt:before {
    content: fa-content($fa-var-sign-in-alt);
  }
}
@mixin fa-sign-language {
  .#{$fa-css-prefix}-sign-language:before {
    content: fa-content($fa-var-sign-language);
  }
}
@mixin fa-sign-out-alt {
  .#{$fa-css-prefix}-sign-out-alt:before {
    content: fa-content($fa-var-sign-out-alt);
  }
}
@mixin fa-signal {
  .#{$fa-css-prefix}-signal:before {
    content: fa-content($fa-var-signal);
  }
}
@mixin fa-signature {
  .#{$fa-css-prefix}-signature:before {
    content: fa-content($fa-var-signature);
  }
}
@mixin fa-simplybuilt {
  .#{$fa-css-prefix}-simplybuilt:before {
    content: fa-content($fa-var-simplybuilt);
  }
}
@mixin fa-sistrix {
  .#{$fa-css-prefix}-sistrix:before {
    content: fa-content($fa-var-sistrix);
  }
}
@mixin fa-sitemap {
  .#{$fa-css-prefix}-sitemap:before {
    content: fa-content($fa-var-sitemap);
  }
}
@mixin fa-sith {
  .#{$fa-css-prefix}-sith:before {
    content: fa-content($fa-var-sith);
  }
}
@mixin fa-skull {
  .#{$fa-css-prefix}-skull:before {
    content: fa-content($fa-var-skull);
  }
}
@mixin fa-skyatlas {
  .#{$fa-css-prefix}-skyatlas:before {
    content: fa-content($fa-var-skyatlas);
  }
}
@mixin fa-skype {
  .#{$fa-css-prefix}-skype:before {
    content: fa-content($fa-var-skype);
  }
}
@mixin fa-slack {
  .#{$fa-css-prefix}-slack:before {
    content: fa-content($fa-var-slack);
  }
}
@mixin fa-slack-hash {
  .#{$fa-css-prefix}-slack-hash:before {
    content: fa-content($fa-var-slack-hash);
  }
}
@mixin fa-sliders-h {
  .#{$fa-css-prefix}-sliders-h:before {
    content: fa-content($fa-var-sliders-h);
  }
}
@mixin fa-slideshare {
  .#{$fa-css-prefix}-slideshare:before {
    content: fa-content($fa-var-slideshare);
  }
}
@mixin fa-smile {
  .#{$fa-css-prefix}-smile:before {
    content: fa-content($fa-var-smile);
  }
}
@mixin fa-smile-beam {
  .#{$fa-css-prefix}-smile-beam:before {
    content: fa-content($fa-var-smile-beam);
  }
}
@mixin fa-smile-wink {
  .#{$fa-css-prefix}-smile-wink:before {
    content: fa-content($fa-var-smile-wink);
  }
}
@mixin fa-smoking {
  .#{$fa-css-prefix}-smoking:before {
    content: fa-content($fa-var-smoking);
  }
}
@mixin fa-smoking-ban {
  .#{$fa-css-prefix}-smoking-ban:before {
    content: fa-content($fa-var-smoking-ban);
  }
}
@mixin fa-snapchat {
  .#{$fa-css-prefix}-snapchat:before {
    content: fa-content($fa-var-snapchat);
  }
}
@mixin fa-snapchat-ghost {
  .#{$fa-css-prefix}-snapchat-ghost:before {
    content: fa-content($fa-var-snapchat-ghost);
  }
}
@mixin fa-snapchat-square {
  .#{$fa-css-prefix}-snapchat-square:before {
    content: fa-content($fa-var-snapchat-square);
  }
}
@mixin fa-snowflake {
  .#{$fa-css-prefix}-snowflake:before {
    content: fa-content($fa-var-snowflake);
  }
}
@mixin fa-socks {
  .#{$fa-css-prefix}-socks:before {
    content: fa-content($fa-var-socks);
  }
}
@mixin fa-solar-panel {
  .#{$fa-css-prefix}-solar-panel:before {
    content: fa-content($fa-var-solar-panel);
  }
}
@mixin fa-sort {
  .#{$fa-css-prefix}-sort:before {
    content: fa-content($fa-var-sort);
  }
}
@mixin fa-sort-alpha-down {
  .#{$fa-css-prefix}-sort-alpha-down:before {
    content: fa-content($fa-var-sort-alpha-down);
  }
}
@mixin fa-sort-alpha-up {
  .#{$fa-css-prefix}-sort-alpha-up:before {
    content: fa-content($fa-var-sort-alpha-up);
  }
}
@mixin fa-sort-amount-down {
  .#{$fa-css-prefix}-sort-amount-down:before {
    content: fa-content($fa-var-sort-amount-down);
  }
}
@mixin fa-sort-amount-up {
  .#{$fa-css-prefix}-sort-amount-up:before {
    content: fa-content($fa-var-sort-amount-up);
  }
}
@mixin fa-sort-down {
  .#{$fa-css-prefix}-sort-down:before {
    content: fa-content($fa-var-sort-down);
  }
}
@mixin fa-sort-numeric-down {
  .#{$fa-css-prefix}-sort-numeric-down:before {
    content: fa-content($fa-var-sort-numeric-down);
  }
}
@mixin fa-sort-numeric-up {
  .#{$fa-css-prefix}-sort-numeric-up:before {
    content: fa-content($fa-var-sort-numeric-up);
  }
}
@mixin fa-sort-up {
  .#{$fa-css-prefix}-sort-up:before {
    content: fa-content($fa-var-sort-up);
  }
}
@mixin fa-soundcloud {
  .#{$fa-css-prefix}-soundcloud:before {
    content: fa-content($fa-var-soundcloud);
  }
}
@mixin fa-spa {
  .#{$fa-css-prefix}-spa:before {
    content: fa-content($fa-var-spa);
  }
}
@mixin fa-space-shuttle {
  .#{$fa-css-prefix}-space-shuttle:before {
    content: fa-content($fa-var-space-shuttle);
  }
}
@mixin fa-speakap {
  .#{$fa-css-prefix}-speakap:before {
    content: fa-content($fa-var-speakap);
  }
}
@mixin fa-spinner {
  .#{$fa-css-prefix}-spinner:before {
    content: fa-content($fa-var-spinner);
  }
}
@mixin fa-splotch {
  .#{$fa-css-prefix}-splotch:before {
    content: fa-content($fa-var-splotch);
  }
}
@mixin fa-spotify {
  .#{$fa-css-prefix}-spotify:before {
    content: fa-content($fa-var-spotify);
  }
}
@mixin fa-spray-can {
  .#{$fa-css-prefix}-spray-can:before {
    content: fa-content($fa-var-spray-can);
  }
}
@mixin fa-square {
  .#{$fa-css-prefix}-square:before {
    content: fa-content($fa-var-square);
  }
}
@mixin fa-square-full {
  .#{$fa-css-prefix}-square-full:before {
    content: fa-content($fa-var-square-full);
  }
}
@mixin fa-square-root-alt {
  .#{$fa-css-prefix}-square-root-alt:before {
    content: fa-content($fa-var-square-root-alt);
  }
}
@mixin fa-squarespace {
  .#{$fa-css-prefix}-squarespace:before {
    content: fa-content($fa-var-squarespace);
  }
}
@mixin fa-stack-exchange {
  .#{$fa-css-prefix}-stack-exchange:before {
    content: fa-content($fa-var-stack-exchange);
  }
}
@mixin fa-stack-overflow {
  .#{$fa-css-prefix}-stack-overflow:before {
    content: fa-content($fa-var-stack-overflow);
  }
}
@mixin fa-stamp {
  .#{$fa-css-prefix}-stamp:before {
    content: fa-content($fa-var-stamp);
  }
}
@mixin fa-star {
  .#{$fa-css-prefix}-star:before {
    content: fa-content($fa-var-star);
  }
}
@mixin fa-star-and-crescent {
  .#{$fa-css-prefix}-star-and-crescent:before {
    content: fa-content($fa-var-star-and-crescent);
  }
}
@mixin fa-star-half {
  .#{$fa-css-prefix}-star-half:before {
    content: fa-content($fa-var-star-half);
  }
}
@mixin fa-star-half-alt {
  .#{$fa-css-prefix}-star-half-alt:before {
    content: fa-content($fa-var-star-half-alt);
  }
}
@mixin fa-star-of-david {
  .#{$fa-css-prefix}-star-of-david:before {
    content: fa-content($fa-var-star-of-david);
  }
}
@mixin fa-star-of-life {
  .#{$fa-css-prefix}-star-of-life:before {
    content: fa-content($fa-var-star-of-life);
  }
}
@mixin fa-staylinked {
  .#{$fa-css-prefix}-staylinked:before {
    content: fa-content($fa-var-staylinked);
  }
}
@mixin fa-steam {
  .#{$fa-css-prefix}-steam:before {
    content: fa-content($fa-var-steam);
  }
}
@mixin fa-steam-square {
  .#{$fa-css-prefix}-steam-square:before {
    content: fa-content($fa-var-steam-square);
  }
}
@mixin fa-steam-symbol {
  .#{$fa-css-prefix}-steam-symbol:before {
    content: fa-content($fa-var-steam-symbol);
  }
}
@mixin fa-step-backward {
  .#{$fa-css-prefix}-step-backward:before {
    content: fa-content($fa-var-step-backward);
  }
}
@mixin fa-step-forward {
  .#{$fa-css-prefix}-step-forward:before {
    content: fa-content($fa-var-step-forward);
  }
}
@mixin fa-stethoscope {
  .#{$fa-css-prefix}-stethoscope:before {
    content: fa-content($fa-var-stethoscope);
  }
}
@mixin fa-sticker-mule {
  .#{$fa-css-prefix}-sticker-mule:before {
    content: fa-content($fa-var-sticker-mule);
  }
}
@mixin fa-sticky-note {
  .#{$fa-css-prefix}-sticky-note:before {
    content: fa-content($fa-var-sticky-note);
  }
}
@mixin fa-stop {
  .#{$fa-css-prefix}-stop:before {
    content: fa-content($fa-var-stop);
  }
}
@mixin fa-stop-circle {
  .#{$fa-css-prefix}-stop-circle:before {
    content: fa-content($fa-var-stop-circle);
  }
}
@mixin fa-stopwatch {
  .#{$fa-css-prefix}-stopwatch:before {
    content: fa-content($fa-var-stopwatch);
  }
}
@mixin fa-store {
  .#{$fa-css-prefix}-store:before {
    content: fa-content($fa-var-store);
  }
}
@mixin fa-store-alt {
  .#{$fa-css-prefix}-store-alt:before {
    content: fa-content($fa-var-store-alt);
  }
}
@mixin fa-strava {
  .#{$fa-css-prefix}-strava:before {
    content: fa-content($fa-var-strava);
  }
}
@mixin fa-stream {
  .#{$fa-css-prefix}-stream:before {
    content: fa-content($fa-var-stream);
  }
}
@mixin fa-street-view {
  .#{$fa-css-prefix}-street-view:before {
    content: fa-content($fa-var-street-view);
  }
}
@mixin fa-strikethrough {
  .#{$fa-css-prefix}-strikethrough:before {
    content: fa-content($fa-var-strikethrough);
  }
}
@mixin fa-stripe {
  .#{$fa-css-prefix}-stripe:before {
    content: fa-content($fa-var-stripe);
  }
}
@mixin fa-stripe-s {
  .#{$fa-css-prefix}-stripe-s:before {
    content: fa-content($fa-var-stripe-s);
  }
}
@mixin fa-stroopwafel {
  .#{$fa-css-prefix}-stroopwafel:before {
    content: fa-content($fa-var-stroopwafel);
  }
}
@mixin fa-studiovinari {
  .#{$fa-css-prefix}-studiovinari:before {
    content: fa-content($fa-var-studiovinari);
  }
}
@mixin fa-stumbleupon {
  .#{$fa-css-prefix}-stumbleupon:before {
    content: fa-content($fa-var-stumbleupon);
  }
}
@mixin fa-stumbleupon-circle {
  .#{$fa-css-prefix}-stumbleupon-circle:before {
    content: fa-content($fa-var-stumbleupon-circle);
  }
}
@mixin fa-subscript {
  .#{$fa-css-prefix}-subscript:before {
    content: fa-content($fa-var-subscript);
  }
}
@mixin fa-subway {
  .#{$fa-css-prefix}-subway:before {
    content: fa-content($fa-var-subway);
  }
}
@mixin fa-suitcase {
  .#{$fa-css-prefix}-suitcase:before {
    content: fa-content($fa-var-suitcase);
  }
}
@mixin fa-suitcase-rolling {
  .#{$fa-css-prefix}-suitcase-rolling:before {
    content: fa-content($fa-var-suitcase-rolling);
  }
}
@mixin fa-sun {
  .#{$fa-css-prefix}-sun:before {
    content: fa-content($fa-var-sun);
  }
}
@mixin fa-superpowers {
  .#{$fa-css-prefix}-superpowers:before {
    content: fa-content($fa-var-superpowers);
  }
}
@mixin fa-superscript {
  .#{$fa-css-prefix}-superscript:before {
    content: fa-content($fa-var-superscript);
  }
}
@mixin fa-supple {
  .#{$fa-css-prefix}-supple:before {
    content: fa-content($fa-var-supple);
  }
}
@mixin fa-surprise {
  .#{$fa-css-prefix}-surprise:before {
    content: fa-content($fa-var-surprise);
  }
}
@mixin fa-swatchbook {
  .#{$fa-css-prefix}-swatchbook:before {
    content: fa-content($fa-var-swatchbook);
  }
}
@mixin fa-swimmer {
  .#{$fa-css-prefix}-swimmer:before {
    content: fa-content($fa-var-swimmer);
  }
}
@mixin fa-swimming-pool {
  .#{$fa-css-prefix}-swimming-pool:before {
    content: fa-content($fa-var-swimming-pool);
  }
}
@mixin fa-synagogue {
  .#{$fa-css-prefix}-synagogue:before {
    content: fa-content($fa-var-synagogue);
  }
}
@mixin fa-sync {
  .#{$fa-css-prefix}-sync:before {
    content: fa-content($fa-var-sync);
  }
}
@mixin fa-sync-alt {
  .#{$fa-css-prefix}-sync-alt:before {
    content: fa-content($fa-var-sync-alt);
  }
}
@mixin fa-syringe {
  .#{$fa-css-prefix}-syringe:before {
    content: fa-content($fa-var-syringe);
  }
}
@mixin fa-table {
  .#{$fa-css-prefix}-table:before {
    content: fa-content($fa-var-table);
  }
}
@mixin fa-table-tennis {
  .#{$fa-css-prefix}-table-tennis:before {
    content: fa-content($fa-var-table-tennis);
  }
}
@mixin fa-tablet {
  .#{$fa-css-prefix}-tablet:before {
    content: fa-content($fa-var-tablet);
  }
}
@mixin fa-tablet-alt {
  .#{$fa-css-prefix}-tablet-alt:before {
    content: fa-content($fa-var-tablet-alt);
  }
}
@mixin fa-tablets {
  .#{$fa-css-prefix}-tablets:before {
    content: fa-content($fa-var-tablets);
  }
}
@mixin fa-tachometer-alt {
  .#{$fa-css-prefix}-tachometer-alt:before {
    content: fa-content($fa-var-tachometer-alt);
  }
}
@mixin fa-tag {
  .#{$fa-css-prefix}-tag:before {
    content: fa-content($fa-var-tag);
  }
}
@mixin fa-tags {
  .#{$fa-css-prefix}-tags:before {
    content: fa-content($fa-var-tags);
  }
}
@mixin fa-tape {
  .#{$fa-css-prefix}-tape:before {
    content: fa-content($fa-var-tape);
  }
}
@mixin fa-tasks {
  .#{$fa-css-prefix}-tasks:before {
    content: fa-content($fa-var-tasks);
  }
}
@mixin fa-taxi {
  .#{$fa-css-prefix}-taxi:before {
    content: fa-content($fa-var-taxi);
  }
}
@mixin fa-teamspeak {
  .#{$fa-css-prefix}-teamspeak:before {
    content: fa-content($fa-var-teamspeak);
  }
}
@mixin fa-teeth {
  .#{$fa-css-prefix}-teeth:before {
    content: fa-content($fa-var-teeth);
  }
}
@mixin fa-teeth-open {
  .#{$fa-css-prefix}-teeth-open:before {
    content: fa-content($fa-var-teeth-open);
  }
}
@mixin fa-telegram {
  .#{$fa-css-prefix}-telegram:before {
    content: fa-content($fa-var-telegram);
  }
}
@mixin fa-telegram-plane {
  .#{$fa-css-prefix}-telegram-plane:before {
    content: fa-content($fa-var-telegram-plane);
  }
}
@mixin fa-tencent-weibo {
  .#{$fa-css-prefix}-tencent-weibo:before {
    content: fa-content($fa-var-tencent-weibo);
  }
}
@mixin fa-terminal {
  .#{$fa-css-prefix}-terminal:before {
    content: fa-content($fa-var-terminal);
  }
}
@mixin fa-text-height {
  .#{$fa-css-prefix}-text-height:before {
    content: fa-content($fa-var-text-height);
  }
}
@mixin fa-text-width {
  .#{$fa-css-prefix}-text-width:before {
    content: fa-content($fa-var-text-width);
  }
}
@mixin fa-th {
  .#{$fa-css-prefix}-th:before {
    content: fa-content($fa-var-th);
  }
}
@mixin fa-th-large {
  .#{$fa-css-prefix}-th-large:before {
    content: fa-content($fa-var-th-large);
  }
}
@mixin fa-th-list {
  .#{$fa-css-prefix}-th-list:before {
    content: fa-content($fa-var-th-list);
  }
}
@mixin fa-the-red-yeti {
  .#{$fa-css-prefix}-the-red-yeti:before {
    content: fa-content($fa-var-the-red-yeti);
  }
}
@mixin fa-theater-masks {
  .#{$fa-css-prefix}-theater-masks:before {
    content: fa-content($fa-var-theater-masks);
  }
}
@mixin fa-themeco {
  .#{$fa-css-prefix}-themeco:before {
    content: fa-content($fa-var-themeco);
  }
}
@mixin fa-themeisle {
  .#{$fa-css-prefix}-themeisle:before {
    content: fa-content($fa-var-themeisle);
  }
}
@mixin fa-thermometer {
  .#{$fa-css-prefix}-thermometer:before {
    content: fa-content($fa-var-thermometer);
  }
}
@mixin fa-thermometer-empty {
  .#{$fa-css-prefix}-thermometer-empty:before {
    content: fa-content($fa-var-thermometer-empty);
  }
}
@mixin fa-thermometer-full {
  .#{$fa-css-prefix}-thermometer-full:before {
    content: fa-content($fa-var-thermometer-full);
  }
}
@mixin fa-thermometer-half {
  .#{$fa-css-prefix}-thermometer-half:before {
    content: fa-content($fa-var-thermometer-half);
  }
}
@mixin fa-thermometer-quarter {
  .#{$fa-css-prefix}-thermometer-quarter:before {
    content: fa-content($fa-var-thermometer-quarter);
  }
}
@mixin fa-thermometer-three-quarters {
  .#{$fa-css-prefix}-thermometer-three-quarters:before {
    content: fa-content($fa-var-thermometer-three-quarters);
  }
}
@mixin fa-thumbs-down {
  .#{$fa-css-prefix}-thumbs-down:before {
    content: fa-content($fa-var-thumbs-down);
  }
}
@mixin fa-thumbs-up {
  .#{$fa-css-prefix}-thumbs-up:before {
    content: fa-content($fa-var-thumbs-up);
  }
}
@mixin fa-thumbtack {
  .#{$fa-css-prefix}-thumbtack:before {
    content: fa-content($fa-var-thumbtack);
  }
}
@mixin fa-ticket-alt {
  .#{$fa-css-prefix}-ticket-alt:before {
    content: fa-content($fa-var-ticket-alt);
  }
}
@mixin fa-times {
  .#{$fa-css-prefix}-times:before {
    content: fa-content($fa-var-times);
  }
}
@mixin fa-times-circle {
  .#{$fa-css-prefix}-times-circle:before {
    content: fa-content($fa-var-times-circle);
  }
}
@mixin fa-tint {
  .#{$fa-css-prefix}-tint:before {
    content: fa-content($fa-var-tint);
  }
}
@mixin fa-tint-slash {
  .#{$fa-css-prefix}-tint-slash:before {
    content: fa-content($fa-var-tint-slash);
  }
}
@mixin fa-tired {
  .#{$fa-css-prefix}-tired:before {
    content: fa-content($fa-var-tired);
  }
}
@mixin fa-toggle-off {
  .#{$fa-css-prefix}-toggle-off:before {
    content: fa-content($fa-var-toggle-off);
  }
}
@mixin fa-toggle-on {
  .#{$fa-css-prefix}-toggle-on:before {
    content: fa-content($fa-var-toggle-on);
  }
}
@mixin fa-toolbox {
  .#{$fa-css-prefix}-toolbox:before {
    content: fa-content($fa-var-toolbox);
  }
}
@mixin fa-tooth {
  .#{$fa-css-prefix}-tooth:before {
    content: fa-content($fa-var-tooth);
  }
}
@mixin fa-torah {
  .#{$fa-css-prefix}-torah:before {
    content: fa-content($fa-var-torah);
  }
}
@mixin fa-torii-gate {
  .#{$fa-css-prefix}-torii-gate:before {
    content: fa-content($fa-var-torii-gate);
  }
}
@mixin fa-trade-federation {
  .#{$fa-css-prefix}-trade-federation:before {
    content: fa-content($fa-var-trade-federation);
  }
}
@mixin fa-trademark {
  .#{$fa-css-prefix}-trademark:before {
    content: fa-content($fa-var-trademark);
  }
}
@mixin fa-traffic-light {
  .#{$fa-css-prefix}-traffic-light:before {
    content: fa-content($fa-var-traffic-light);
  }
}
@mixin fa-train {
  .#{$fa-css-prefix}-train:before {
    content: fa-content($fa-var-train);
  }
}
@mixin fa-transgender {
  .#{$fa-css-prefix}-transgender:before {
    content: fa-content($fa-var-transgender);
  }
}
@mixin fa-transgender-alt {
  .#{$fa-css-prefix}-transgender-alt:before {
    content: fa-content($fa-var-transgender-alt);
  }
}
@mixin fa-trash {
  .#{$fa-css-prefix}-trash:before {
    content: fa-content($fa-var-trash);
  }
}
@mixin fa-trash-alt {
  .#{$fa-css-prefix}-trash-alt:before {
    content: fa-content($fa-var-trash-alt);
  }
}
@mixin fa-tree {
  .#{$fa-css-prefix}-tree:before {
    content: fa-content($fa-var-tree);
  }
}
@mixin fa-trello {
  .#{$fa-css-prefix}-trello:before {
    content: fa-content($fa-var-trello);
  }
}
@mixin fa-tripadvisor {
  .#{$fa-css-prefix}-tripadvisor:before {
    content: fa-content($fa-var-tripadvisor);
  }
}
@mixin fa-trophy {
  .#{$fa-css-prefix}-trophy:before {
    content: fa-content($fa-var-trophy);
  }
}
@mixin fa-truck {
  .#{$fa-css-prefix}-truck:before {
    content: fa-content($fa-var-truck);
  }
}
@mixin fa-truck-loading {
  .#{$fa-css-prefix}-truck-loading:before {
    content: fa-content($fa-var-truck-loading);
  }
}
@mixin fa-truck-monster {
  .#{$fa-css-prefix}-truck-monster:before {
    content: fa-content($fa-var-truck-monster);
  }
}
@mixin fa-truck-moving {
  .#{$fa-css-prefix}-truck-moving:before {
    content: fa-content($fa-var-truck-moving);
  }
}
@mixin fa-truck-pickup {
  .#{$fa-css-prefix}-truck-pickup:before {
    content: fa-content($fa-var-truck-pickup);
  }
}
@mixin fa-tshirt {
  .#{$fa-css-prefix}-tshirt:before {
    content: fa-content($fa-var-tshirt);
  }
}
@mixin fa-tty {
  .#{$fa-css-prefix}-tty:before {
    content: fa-content($fa-var-tty);
  }
}
@mixin fa-tumblr {
  .#{$fa-css-prefix}-tumblr:before {
    content: fa-content($fa-var-tumblr);
  }
}
@mixin fa-tumblr-square {
  .#{$fa-css-prefix}-tumblr-square:before {
    content: fa-content($fa-var-tumblr-square);
  }
}
@mixin fa-tv {
  .#{$fa-css-prefix}-tv:before {
    content: fa-content($fa-var-tv);
  }
}
@mixin fa-twitch {
  .#{$fa-css-prefix}-twitch:before {
    content: fa-content($fa-var-twitch);
  }
}
@mixin fa-twitter {
  .#{$fa-css-prefix}-twitter:before {
    content: fa-content($fa-var-twitter);
  }
}
@mixin fa-twitter-square {
  .#{$fa-css-prefix}-twitter-square:before {
    content: fa-content($fa-var-twitter-square);
  }
}
@mixin fa-typo3 {
  .#{$fa-css-prefix}-typo3:before {
    content: fa-content($fa-var-typo3);
  }
}
@mixin fa-uber {
  .#{$fa-css-prefix}-uber:before {
    content: fa-content($fa-var-uber);
  }
}
@mixin fa-uikit {
  .#{$fa-css-prefix}-uikit:before {
    content: fa-content($fa-var-uikit);
  }
}
@mixin fa-umbrella {
  .#{$fa-css-prefix}-umbrella:before {
    content: fa-content($fa-var-umbrella);
  }
}
@mixin fa-umbrella-beach {
  .#{$fa-css-prefix}-umbrella-beach:before {
    content: fa-content($fa-var-umbrella-beach);
  }
}
@mixin fa-underline {
  .#{$fa-css-prefix}-underline:before {
    content: fa-content($fa-var-underline);
  }
}
@mixin fa-undo {
  .#{$fa-css-prefix}-undo:before {
    content: fa-content($fa-var-undo);
  }
}
@mixin fa-undo-alt {
  .#{$fa-css-prefix}-undo-alt:before {
    content: fa-content($fa-var-undo-alt);
  }
}
@mixin fa-uniregistry {
  .#{$fa-css-prefix}-uniregistry:before {
    content: fa-content($fa-var-uniregistry);
  }
}
@mixin fa-universal-access {
  .#{$fa-css-prefix}-universal-access:before {
    content: fa-content($fa-var-universal-access);
  }
}
@mixin fa-university {
  .#{$fa-css-prefix}-university:before {
    content: fa-content($fa-var-university);
  }
}
@mixin fa-unlink {
  .#{$fa-css-prefix}-unlink:before {
    content: fa-content($fa-var-unlink);
  }
}
@mixin fa-unlock {
  .#{$fa-css-prefix}-unlock:before {
    content: fa-content($fa-var-unlock);
  }
}
@mixin fa-unlock-alt {
  .#{$fa-css-prefix}-unlock-alt:before {
    content: fa-content($fa-var-unlock-alt);
  }
}
@mixin fa-untappd {
  .#{$fa-css-prefix}-untappd:before {
    content: fa-content($fa-var-untappd);
  }
}
@mixin fa-upload {
  .#{$fa-css-prefix}-upload:before {
    content: fa-content($fa-var-upload);
  }
}
@mixin fa-usb {
  .#{$fa-css-prefix}-usb:before {
    content: fa-content($fa-var-usb);
  }
}
@mixin fa-user {
  .#{$fa-css-prefix}-user:before {
    content: fa-content($fa-var-user);
  }
}
@mixin fa-user-alt {
  .#{$fa-css-prefix}-user-alt:before {
    content: fa-content($fa-var-user-alt);
  }
}
@mixin fa-user-alt-slash {
  .#{$fa-css-prefix}-user-alt-slash:before {
    content: fa-content($fa-var-user-alt-slash);
  }
}
@mixin fa-user-astronaut {
  .#{$fa-css-prefix}-user-astronaut:before {
    content: fa-content($fa-var-user-astronaut);
  }
}
@mixin fa-user-check {
  .#{$fa-css-prefix}-user-check:before {
    content: fa-content($fa-var-user-check);
  }
}
@mixin fa-user-circle {
  .#{$fa-css-prefix}-user-circle:before {
    content: fa-content($fa-var-user-circle);
  }
}
@mixin fa-user-clock {
  .#{$fa-css-prefix}-user-clock:before {
    content: fa-content($fa-var-user-clock);
  }
}
@mixin fa-user-cog {
  .#{$fa-css-prefix}-user-cog:before {
    content: fa-content($fa-var-user-cog);
  }
}
@mixin fa-user-edit {
  .#{$fa-css-prefix}-user-edit:before {
    content: fa-content($fa-var-user-edit);
  }
}
@mixin fa-user-friends {
  .#{$fa-css-prefix}-user-friends:before {
    content: fa-content($fa-var-user-friends);
  }
}
@mixin fa-user-graduate {
  .#{$fa-css-prefix}-user-graduate:before {
    content: fa-content($fa-var-user-graduate);
  }
}
@mixin fa-user-lock {
  .#{$fa-css-prefix}-user-lock:before {
    content: fa-content($fa-var-user-lock);
  }
}
@mixin fa-user-md {
  .#{$fa-css-prefix}-user-md:before {
    content: fa-content($fa-var-user-md);
  }
}
@mixin fa-user-minus {
  .#{$fa-css-prefix}-user-minus:before {
    content: fa-content($fa-var-user-minus);
  }
}
@mixin fa-user-ninja {
  .#{$fa-css-prefix}-user-ninja:before {
    content: fa-content($fa-var-user-ninja);
  }
}
@mixin fa-user-plus {
  .#{$fa-css-prefix}-user-plus:before {
    content: fa-content($fa-var-user-plus);
  }
}
@mixin fa-user-secret {
  .#{$fa-css-prefix}-user-secret:before {
    content: fa-content($fa-var-user-secret);
  }
}
@mixin fa-user-shield {
  .#{$fa-css-prefix}-user-shield:before {
    content: fa-content($fa-var-user-shield);
  }
}
@mixin fa-user-slash {
  .#{$fa-css-prefix}-user-slash:before {
    content: fa-content($fa-var-user-slash);
  }
}
@mixin fa-user-tag {
  .#{$fa-css-prefix}-user-tag:before {
    content: fa-content($fa-var-user-tag);
  }
}
@mixin fa-user-tie {
  .#{$fa-css-prefix}-user-tie:before {
    content: fa-content($fa-var-user-tie);
  }
}
@mixin fa-user-times {
  .#{$fa-css-prefix}-user-times:before {
    content: fa-content($fa-var-user-times);
  }
}
@mixin fa-users {
  .#{$fa-css-prefix}-users:before {
    content: fa-content($fa-var-users);
  }
}
@mixin fa-users-cog {
  .#{$fa-css-prefix}-users-cog:before {
    content: fa-content($fa-var-users-cog);
  }
}
@mixin fa-ussunnah {
  .#{$fa-css-prefix}-ussunnah:before {
    content: fa-content($fa-var-ussunnah);
  }
}
@mixin fa-utensil-spoon {
  .#{$fa-css-prefix}-utensil-spoon:before {
    content: fa-content($fa-var-utensil-spoon);
  }
}
@mixin fa-utensils {
  .#{$fa-css-prefix}-utensils:before {
    content: fa-content($fa-var-utensils);
  }
}
@mixin fa-vaadin {
  .#{$fa-css-prefix}-vaadin:before {
    content: fa-content($fa-var-vaadin);
  }
}
@mixin fa-vector-square {
  .#{$fa-css-prefix}-vector-square:before {
    content: fa-content($fa-var-vector-square);
  }
}
@mixin fa-venus {
  .#{$fa-css-prefix}-venus:before {
    content: fa-content($fa-var-venus);
  }
}
@mixin fa-venus-double {
  .#{$fa-css-prefix}-venus-double:before {
    content: fa-content($fa-var-venus-double);
  }
}
@mixin fa-venus-mars {
  .#{$fa-css-prefix}-venus-mars:before {
    content: fa-content($fa-var-venus-mars);
  }
}
@mixin fa-viacoin {
  .#{$fa-css-prefix}-viacoin:before {
    content: fa-content($fa-var-viacoin);
  }
}
@mixin fa-viadeo {
  .#{$fa-css-prefix}-viadeo:before {
    content: fa-content($fa-var-viadeo);
  }
}
@mixin fa-viadeo-square {
  .#{$fa-css-prefix}-viadeo-square:before {
    content: fa-content($fa-var-viadeo-square);
  }
}
@mixin fa-vial {
  .#{$fa-css-prefix}-vial:before {
    content: fa-content($fa-var-vial);
  }
}
@mixin fa-vials {
  .#{$fa-css-prefix}-vials:before {
    content: fa-content($fa-var-vials);
  }
}
@mixin fa-viber {
  .#{$fa-css-prefix}-viber:before {
    content: fa-content($fa-var-viber);
  }
}
@mixin fa-video {
  .#{$fa-css-prefix}-video:before {
    content: fa-content($fa-var-video);
  }
}
@mixin fa-video-slash {
  .#{$fa-css-prefix}-video-slash:before {
    content: fa-content($fa-var-video-slash);
  }
}
@mixin fa-vihara {
  .#{$fa-css-prefix}-vihara:before {
    content: fa-content($fa-var-vihara);
  }
}
@mixin fa-vimeo {
  .#{$fa-css-prefix}-vimeo:before {
    content: fa-content($fa-var-vimeo);
  }
}
@mixin fa-vimeo-square {
  .#{$fa-css-prefix}-vimeo-square:before {
    content: fa-content($fa-var-vimeo-square);
  }
}
@mixin fa-vimeo-v {
  .#{$fa-css-prefix}-vimeo-v:before {
    content: fa-content($fa-var-vimeo-v);
  }
}
@mixin fa-vine {
  .#{$fa-css-prefix}-vine:before {
    content: fa-content($fa-var-vine);
  }
}
@mixin fa-vk {
  .#{$fa-css-prefix}-vk:before {
    content: fa-content($fa-var-vk);
  }
}
@mixin fa-vnv {
  .#{$fa-css-prefix}-vnv:before {
    content: fa-content($fa-var-vnv);
  }
}
@mixin fa-volleyball-ball {
  .#{$fa-css-prefix}-volleyball-ball:before {
    content: fa-content($fa-var-volleyball-ball);
  }
}
@mixin fa-volume-down {
  .#{$fa-css-prefix}-volume-down:before {
    content: fa-content($fa-var-volume-down);
  }
}
@mixin fa-volume-off {
  .#{$fa-css-prefix}-volume-off:before {
    content: fa-content($fa-var-volume-off);
  }
}
@mixin fa-volume-up {
  .#{$fa-css-prefix}-volume-up:before {
    content: fa-content($fa-var-volume-up);
  }
}
@mixin fa-vuejs {
  .#{$fa-css-prefix}-vuejs:before {
    content: fa-content($fa-var-vuejs);
  }
}
@mixin fa-walking {
  .#{$fa-css-prefix}-walking:before {
    content: fa-content($fa-var-walking);
  }
}
@mixin fa-wallet {
  .#{$fa-css-prefix}-wallet:before {
    content: fa-content($fa-var-wallet);
  }
}
@mixin fa-warehouse {
  .#{$fa-css-prefix}-warehouse:before {
    content: fa-content($fa-var-warehouse);
  }
}
@mixin fa-weebly {
  .#{$fa-css-prefix}-weebly:before {
    content: fa-content($fa-var-weebly);
  }
}
@mixin fa-weibo {
  .#{$fa-css-prefix}-weibo:before {
    content: fa-content($fa-var-weibo);
  }
}
@mixin fa-weight {
  .#{$fa-css-prefix}-weight:before {
    content: fa-content($fa-var-weight);
  }
}
@mixin fa-weight-hanging {
  .#{$fa-css-prefix}-weight-hanging:before {
    content: fa-content($fa-var-weight-hanging);
  }
}
@mixin fa-weixin {
  .#{$fa-css-prefix}-weixin:before {
    content: fa-content($fa-var-weixin);
  }
}
@mixin fa-whatsapp {
  .#{$fa-css-prefix}-whatsapp:before {
    content: fa-content($fa-var-whatsapp);
  }
}
@mixin fa-whatsapp-square {
  .#{$fa-css-prefix}-whatsapp-square:before {
    content: fa-content($fa-var-whatsapp-square);
  }
}
@mixin fa-wheelchair {
  .#{$fa-css-prefix}-wheelchair:before {
    content: fa-content($fa-var-wheelchair);
  }
}
@mixin fa-whmcs {
  .#{$fa-css-prefix}-whmcs:before {
    content: fa-content($fa-var-whmcs);
  }
}
@mixin fa-wifi {
  .#{$fa-css-prefix}-wifi:before {
    content: fa-content($fa-var-wifi);
  }
}
@mixin fa-wikipedia-w {
  .#{$fa-css-prefix}-wikipedia-w:before {
    content: fa-content($fa-var-wikipedia-w);
  }
}
@mixin fa-window-close {
  .#{$fa-css-prefix}-window-close:before {
    content: fa-content($fa-var-window-close);
  }
}
@mixin fa-window-maximize {
  .#{$fa-css-prefix}-window-maximize:before {
    content: fa-content($fa-var-window-maximize);
  }
}
@mixin fa-window-minimize {
  .#{$fa-css-prefix}-window-minimize:before {
    content: fa-content($fa-var-window-minimize);
  }
}
@mixin fa-window-restore {
  .#{$fa-css-prefix}-window-restore:before {
    content: fa-content($fa-var-window-restore);
  }
}
@mixin fa-windows {
  .#{$fa-css-prefix}-windows:before {
    content: fa-content($fa-var-windows);
  }
}
@mixin fa-wine-glass {
  .#{$fa-css-prefix}-wine-glass:before {
    content: fa-content($fa-var-wine-glass);
  }
}
@mixin fa-wine-glass-alt {
  .#{$fa-css-prefix}-wine-glass-alt:before {
    content: fa-content($fa-var-wine-glass-alt);
  }
}
@mixin fa-wix {
  .#{$fa-css-prefix}-wix:before {
    content: fa-content($fa-var-wix);
  }
}
@mixin fa-wolf-pack-battalion {
  .#{$fa-css-prefix}-wolf-pack-battalion:before {
    content: fa-content($fa-var-wolf-pack-battalion);
  }
}
@mixin fa-won-sign {
  .#{$fa-css-prefix}-won-sign:before {
    content: fa-content($fa-var-won-sign);
  }
}
@mixin fa-wordpress {
  .#{$fa-css-prefix}-wordpress:before {
    content: fa-content($fa-var-wordpress);
  }
}
@mixin fa-wordpress-simple {
  .#{$fa-css-prefix}-wordpress-simple:before {
    content: fa-content($fa-var-wordpress-simple);
  }
}
@mixin fa-wpbeginner {
  .#{$fa-css-prefix}-wpbeginner:before {
    content: fa-content($fa-var-wpbeginner);
  }
}
@mixin fa-wpexplorer {
  .#{$fa-css-prefix}-wpexplorer:before {
    content: fa-content($fa-var-wpexplorer);
  }
}
@mixin fa-wpforms {
  .#{$fa-css-prefix}-wpforms:before {
    content: fa-content($fa-var-wpforms);
  }
}
@mixin fa-wrench {
  .#{$fa-css-prefix}-wrench:before {
    content: fa-content($fa-var-wrench);
  }
}
@mixin fa-x-ray {
  .#{$fa-css-prefix}-x-ray:before {
    content: fa-content($fa-var-x-ray);
  }
}
@mixin fa-xbox {
  .#{$fa-css-prefix}-xbox:before {
    content: fa-content($fa-var-xbox);
  }
}
@mixin fa-xing {
  .#{$fa-css-prefix}-xing:before {
    content: fa-content($fa-var-xing);
  }
}
@mixin fa-xing-square {
  .#{$fa-css-prefix}-xing-square:before {
    content: fa-content($fa-var-xing-square);
  }
}
@mixin fa-y-combinator {
  .#{$fa-css-prefix}-y-combinator:before {
    content: fa-content($fa-var-y-combinator);
  }
}
@mixin fa-yahoo {
  .#{$fa-css-prefix}-yahoo:before {
    content: fa-content($fa-var-yahoo);
  }
}
@mixin fa-yandex {
  .#{$fa-css-prefix}-yandex:before {
    content: fa-content($fa-var-yandex);
  }
}
@mixin fa-yandex-international {
  .#{$fa-css-prefix}-yandex-international:before {
    content: fa-content($fa-var-yandex-international);
  }
}
@mixin fa-yelp {
  .#{$fa-css-prefix}-yelp:before {
    content: fa-content($fa-var-yelp);
  }
}
@mixin fa-yen-sign {
  .#{$fa-css-prefix}-yen-sign:before {
    content: fa-content($fa-var-yen-sign);
  }
}
@mixin fa-yin-yang {
  .#{$fa-css-prefix}-yin-yang:before {
    content: fa-content($fa-var-yin-yang);
  }
}
@mixin fa-yoast {
  .#{$fa-css-prefix}-yoast:before {
    content: fa-content($fa-var-yoast);
  }
}
@mixin fa-youtube {
  .#{$fa-css-prefix}-youtube:before {
    content: fa-content($fa-var-youtube);
  }
}
@mixin fa-youtube-square {
  .#{$fa-css-prefix}-youtube-square:before {
    content: fa-content($fa-var-youtube-square);
  }
}
@mixin fa-zhihu {
  .#{$fa-css-prefix}-zhihu:before {
    content: fa-content($fa-var-zhihu);
  }
}
