@include fa-chevron-right;

.contentbox {
  margin-top: rem(20px);
  margin-bottom: rem(20px);
  padding: rem(15px) rem(15px);
  overflow: hidden;
  line-height: 1.5em;
  background-color: $off-white;
  border-radius: rem(5px);
  box-shadow: 0 rem(2px) rem(5px) rgba(0, 0, 0, 0.2);
  color: $dark-gray;

  &:first-of-type {
    margin-top: 0;
  }

  & .contentbox {
    border-radius: 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    border-left: rem(6px) solid $primary;
    padding: rem(10px);
    margin-top: rem(10px);
    background-color: rgba(white, 0.5);

    li {
      border-color: $primary-light;

      li {
        border-color: $primary-accent;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 0.6em;
    }
  }

  p {
    margin: 0 0 rem(15px) 0;

    &:last-child {
      margin: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.4em 0 0.8em;
  }

  & > h1,
  & > h2,
  & > h3 {
    &:first-child {
      background-color: $primary-dark;
      color: white;
      margin: rem(-15px) rem(-15px) rem(15px);
      padding: rem(20px) rem(15px) 0.6em;
      font-weight: normal;

      &.error {
        background-color: $error;
      }
    }
  }

  &::after {
    clear: both;
  }
}
