.canvas-container {
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  float: left;
  max-width: 100%;
  background-color: white;
  line-height: 0;
  box-shadow: 0 rem(2px) rem(5px) rgba(0, 0, 0, 0.2);

  canvas {
    position: absolute;
    top: 0;
    left: 0;

    &:first-child {
      position: relative;
    }
  }
}
