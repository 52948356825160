@import "https://fonts.googleapis.com/css?family=PT+Serif|Source+Sans+Pro:400,600&display=swap";
body {
  color: #fff;
  font-family: Source Sans Pro, Arial, Helvetica, sans-serif;
}

a {
  color: #00897b;
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  color: #00bfa5;
}

img:not([src]) {
  display: none;
}

.container {
  box-sizing: content-box;
  max-width: 75rem;
  margin: 0 auto;
  padding: 0 .625rem;
}

.fa, .fas, .far, .fal, .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url("fa-solid-900.e05b5fda.eot");
  src: url("fa-solid-900.e05b5fda.eot#iefix") format("embedded-opentype"), url("fa-solid-900.b6f4a2e3.woff2") format("woff2"), url("fa-solid-900.2cedf686.woff") format("woff"), url("fa-solid-900.501e1622.ttf") format("truetype"), url("fa-solid-900.2b9de358.svg#fontawesome") format("svg");
}

.fa, .fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url("fa-brands-400.8c851097.eot");
  src: url("fa-brands-400.8c851097.eot#iefix") format("embedded-opentype"), url("fa-brands-400.a346e127.woff2") format("woff2"), url("fa-brands-400.3b477a33.woff") format("woff"), url("fa-brands-400.d4336036.ttf") format("truetype"), url("fa-brands-400.a785505d.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

.fa-github:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.footer {
  text-align: center;
  margin-top: 1.25rem;
  padding: 0 .625rem 1.875rem;
}

.footer__links {
  padding-bottom: .3125rem;
}

.footer__links a {
  color: #004d40;
  margin-right: 1rem;
  font-size: 1.5rem;
  text-decoration: none;
}

.footer__links a:hover {
  color: #fff;
}

.footer__links a:last-child {
  margin-right: 0;
}

.header {
  color: #fff;
  width: 100%;
  padding: .625rem 0;
}

.header .container {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header__title {
  letter-spacing: .03em;
  text-shadow: 0 .1875rem .25rem #0003;
  flex-flow: row;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: PT Serif, Times New Roman, Times, serif;
  font-size: 2.25rem;
  font-weight: bold;
  display: flex;
}

.header__title img {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: .625rem .9375rem .625rem .625rem;
}

.header__menu {
  z-index: 100;
  letter-spacing: .025rem;
  -webkit-user-select: none;
  user-select: none;
  flex-flow: row;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.header__menu span {
  cursor: default;
}

.header__menu a, .header__menu span {
  margin: 0;
  padding: .625rem .9375rem;
  display: block;
}

.header__menu > li {
  color: #fff;
  text-align: center;
  border-left: 1px solid #fff;
  padding: 0;
  font-weight: bold;
  position: relative;
}

.header__menu > li:first-child {
  border-left: none;
}

.header__menu > li > a, .header__menu > li > span {
  text-shadow: 0 .125rem .1875rem #0003;
  font-weight: bold;
  transition: all .1s ease-in-out;
  position: relative;
  bottom: 0;
}

.header__menu > li:hover > a, .header__menu > li:hover > span {
  text-shadow: 0 .25rem .25rem #0003;
  bottom: .125rem;
}

.header__menu li:hover > .submenu {
  display: block;
}

.header__menu .submenu {
  text-align: left;
  background-color: #fff;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  box-shadow: 0 0 .1875rem .0625rem #0003;
}

.header__menu .submenu > li {
  border-left: .375rem solid #00bfa5;
  width: 100%;
  margin: 0;
  font-weight: normal;
  transition: all .1s ease-in-out;
  position: relative;
}

.header__menu .submenu > li a {
  color: #212121;
  font-weight: normal;
}

.header__menu .submenu > li:hover {
  background-color: #b2dfdb;
  border-left: .5rem solid #00897b;
  margin-right: -.125rem;
}

@media only screen and (width <= 599px) {
  .header .container {
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .header__title {
    padding-right: 2.5rem;
  }

  .header__menu {
    align-self: stretch;
    margin-top: .625rem;
  }

  .header__menu > li {
    flex-grow: 1;
  }
}

body {
  background: url("voronoi.b242d405.svg") 0 0 / 500px 500px, linear-gradient(120deg, #4db6ac 0%, #00bfa5 100%) 0 0 / cover;
  margin: 0;
  padding: 0;
}

.content {
  position: relative;
}

.canvas-container {
  float: left;
  background-color: #fff;
  max-width: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
  line-height: 0;
  position: relative;
  box-shadow: 0 .125rem .3125rem #0003;
}

.canvas-container canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.canvas-container canvas:first-child {
  position: relative;
}

.column {
  float: left;
  margin: 0 .625rem;
  display: block;
}

.column:after {
  clear: left;
}

.prefer-row {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.content-header, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5 {
  color: #333;
  background-color: #fafafa;
  border-left: .625rem solid #00897b;
  border-radius: .3125rem;
  margin: 0 0 1.25rem;
  padding: .3em;
  box-shadow: 0 .125rem .3125rem #0003;
}

.content-header.error, .content > h1.error, .content > h2.error, .content > h3.error, .content > h4.error, .content > h5.error {
  border-color: #f44336;
}

.fa-chevron-right:before {
  content: "";
}

.contentbox {
  color: #333;
  background-color: #fafafa;
  border-radius: .3125rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: .9375rem;
  line-height: 1.5em;
  overflow: hidden;
  box-shadow: 0 .125rem .3125rem #0003;
}

.contentbox:first-of-type {
  margin-top: 0;
}

.contentbox .contentbox {
  border-radius: 0;
}

.contentbox ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.contentbox li {
  background-color: #ffffff80;
  border-left: .375rem solid #009688;
  margin-top: .625rem;
  padding: .625rem;
  position: relative;
}

.contentbox li li {
  border-color: #4db6ac;
}

.contentbox li li li {
  border-color: #00bfa5;
}

.contentbox li h1, .contentbox li h2, .contentbox li h3, .contentbox li h4, .contentbox li h5, .contentbox li h6 {
  margin: 0 0 .6em;
}

.contentbox p {
  margin: 0 0 .9375rem;
}

.contentbox p:last-child {
  margin: 0;
}

.contentbox h1, .contentbox h2, .contentbox h3, .contentbox h4, .contentbox h5, .contentbox h6 {
  margin: .4em 0 .8em;
}

.contentbox > h1:first-child, .contentbox > h2:first-child, .contentbox > h3:first-child {
  color: #fff;
  background-color: #00897b;
  margin: -.9375rem -.9375rem .9375rem;
  padding: 1.25rem .9375rem .6em;
  font-weight: normal;
}

.contentbox > h1:first-child.error, .contentbox > h2:first-child.error, .contentbox > h3:first-child.error {
  background-color: #f44336;
}

.contentbox:after {
  clear: both;
}

.controls {
  float: left;
}

.control input, .control select {
  width: 10em;
}

.control input[type="checkbox"] {
  width: auto;
}

.control input.short-input {
  max-width: 4em;
}

.control__control-point {
  color: #fff;
  white-space: nowrap;
  background-color: #4db6ac;
  border: .125rem solid #009688;
  border-radius: .1875rem;
  align-items: center;
  height: 1em;
  margin: .0625rem;
  padding: .3125rem;
  font-weight: bold;
  display: inline-flex;
}

.control__control-point > * {
  margin-left: .3125rem;
}

.control__control-point > :first-child {
  margin-left: 0;
}

.control__title {
  text-align: right;
  padding-right: .3125rem;
}

.img-desc {
  font-weight: bold;
}

img + .img-desc {
  margin-bottom: 1em;
}

img + .img-desc:last-child {
  margin-bottom: 0;
}

.project-header {
  border-bottom: 1px solid #b3a098;
  align-items: center;
  margin: 0 0 .625rem;
  padding: 0 0 .625rem;
  display: flex;
}

.project-header:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.project-header > * {
  margin-right: .625rem;
}

.project-header img {
  max-width: 3.125rem;
  max-height: 3.125rem;
}

.project-header div {
  white-space: nowrap;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 3.125rem;
}

table.aligned tr td:first-child {
  text-align: right;
  font-weight: bold;
}

.fa-external-link-alt:before {
  content: "";
}

.tiles {
  gap: 10px;
  margin-bottom: 10px;
  display: grid;
}

.tiles--lg {
  grid-template-columns: repeat(3, 1fr);
}

.tiles--sm {
  grid-template-columns: repeat(5, 1fr);
}

@media only screen and (width <= 767px) {
  .tiles--lg {
    grid-template-columns: repeat(2, 1fr);
  }

  .tiles--sm {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (width <= 599px) {
  .tiles--lg {
    grid-template-columns: repeat(1, 1fr);
  }

  .tiles--sm {
    grid-template-columns: repeat(2, 1fr);
  }
}

.tile {
  background-color: #fafafa;
  border-radius: 5px;
  width: 100%;
  font-weight: normal;
  line-height: 0;
  display: block;
  overflow: hidden;
  box-shadow: 0 .125rem .3125rem #0003;
}

.tile .info {
  color: #333;
  padding: .9375rem;
  line-height: 1.2;
}

.tile .info__title {
  font-size: 1.5rem;
  line-height: 1;
}

.tile .info__desc {
  margin-top: .625rem;
}

.tile__image {
  background-color: #fff;
  padding-top: 100%;
  position: relative;
}

.tile img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

a.tile:hover {
  background-color: #eee;
}

/*# sourceMappingURL=404.28d4449e.css.map */
