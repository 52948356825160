$primary-lighter: #b2dfdb;
$primary-light: #4db6ac;
$primary: #009688;
$primary-dark: #00897b;
$primary-darker: #004d40;
$primary-accent: #00bfa5;

$secondary-lighter: #efebe9;
$secondary-light: #b3a098;
$secondary: #795548;

$error: #f44336;

$off-white: #fafafa;

$lighter-gray: #eeeeee;
$gray: #888888;
$dark-gray: #333;
$darker-gray: #212121;

$small: 600px;
$medium: 768px;

$Source-Sans-Pro: 'Source Sans Pro', Arial, Helvetica, sans-serif;
$PT-Serif: 'PT Serif', 'Times New Roman', Times, serif;

$fa-font-path: '~/src/common/assets/fonts';
