body {
  margin: 0;
  padding: 0;
  background: url('~/src/common/assets/img/voronoi.svg'),
    linear-gradient(120deg, $primary-light 0%, $primary-accent 100%);
  background-size: 500px 500px, cover;
  background-position: 0 0, 0 0;
}

.content {
  position: relative;
}
