body {
  font-family: $Source-Sans-Pro;
  // font-weight: 300;
  color: white;
}

a {
  color: $primary-dark;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: $primary-accent;
  }
}

img:not([src]) {
  display: none;
}
