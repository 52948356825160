.header {
  width: 100%;
  padding: rem(10px) 0;
  color: white;

  .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: rem(36px);
    font-family: $PT-Serif;
    font-weight: bold;
    letter-spacing: 0.03em;
    text-shadow: 0 rem(3px) rem(4px) rgba(0, 0, 0, 0.2);

    img {
      width: 50px;
      height: 50px;
      margin: rem(10px);
      margin-right: rem(15px);
      border-radius: 50%;
      border: 1px solid white;
    }
  }

  &__menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    list-style-type: none;
    z-index: 100;
    letter-spacing: rem(0.4px);
    user-select: none;

    span {
      cursor: default;
    }

    a,
    span {
      margin: 0;
      padding: rem(10px) rem(15px);
      display: block;
    }

    & > li {
      padding: 0;
      border-left: 1px solid white;
      position: relative;
      color: white;
      font-weight: bold;
      text-align: center;

      &:first-child {
        border-left: none;
      }

      & > a,
      & > span {
        position: relative;
        bottom: 0;
        text-shadow: 0 rem(2px) rem(3px) rgba(0, 0, 0, 0.2);
        transition: all 0.1s ease-in-out;
        font-weight: bold;
      }

      &:hover > a,
      &:hover > span {
        bottom: rem(2px);
        text-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.2);
      }
    }

    li:hover > .submenu {
      display: block;
    }

    .submenu {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      left: 0;
      display: none;
      background-color: white;
      list-style-type: none;
      box-shadow: 0 0 rem(3px) rem(1px) rgba(0, 0, 0, 0.2);
      overflow: hidden;
      text-align: left;

      & > li {
        width: 100%;
        position: relative;
        margin: 0;
        border-left: rem(6px) solid $primary-accent;
        transition: all 0.1s ease-in-out;
        font-weight: normal;

        a {
          color: $darker-gray;
          font-weight: normal;
        }

        &:hover {
          background-color: $primary-lighter;
          margin-right: rem(-2px);
          border-left: rem(8px) solid $primary-dark;
        }
      }
    }
  }

  @include mobile-only {
    .container {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      padding-right: rem(40px);
    }

    &__menu {
      align-self: stretch;
      margin-top: rem(10px);

      & > li {
        flex-grow: 1;
      }
    }
  }
}
