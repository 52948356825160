.project-header {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-bottom: rem(10px);
  padding-bottom: rem(10px);
  border-bottom: 1px solid $secondary-light;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  & > * {
    margin-right: rem(10px);
  }

  img {
    max-width: rem(50px);
    max-height: rem(50px);
  }

  div {
    font-size: rem(20px);
    font-weight: bold;
    line-height: rem(50px);
    white-space: nowrap;
  }
}
