@include fa-github;
@include fa-linkedin-in;
@include fa-envelope;

.footer {
  margin-top: rem(20px);
  padding: 0 rem(10px) rem(30px);
  text-align: center;

  &__links {
    padding-bottom: rem(5px);

    a {
      font-size: rem(24px);
      text-decoration: none;
      color: $primary-darker;
      margin-right: rem(16px);

      &:hover {
        color: white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
