.column {
  display: block;
  float: left;
  margin: 0 rem(10px);

  &:after {
    clear: left;
  }
}

.prefer-row {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
