@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/normalize.scss';

@import 'layout/container';

@import 'vendor/fontawesome/fontawesome';
@import 'vendor/fontawesome/solid';
@import 'vendor/fontawesome/brands';

@import 'shared/footer';
@import 'shared/header';
@import 'shared/main';

@import 'components/canvas';
@import 'components/column';
@import 'components/content-header';
@import 'components/contentbox';
@import 'components/control';
@import 'components/image-description';
@import 'components/project-header';
@import 'components/tables';
@import 'components/tiles';
